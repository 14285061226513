<template>

    <div>
        <v-card :loading="loading" class="mx-auto" outlined>
            <template slot="progress">
                <v-progress-linear
                        :color="$globalSettings.progressbarClasses"
                        height="10"
                        indeterminate
                ></v-progress-linear>
            </template>

            <v-card-title>
                <v-row>
                    <v-col cols="12" sm="4">
                        <div class="secondary-text mb-2">
                            <v-btn type="button" class="default" :disabled="loading" small icon @click="navigationBack"><v-icon small>fa-angle-left</v-icon></v-btn> Application for {{ $globalHelpers.getEnumLabel($globalEnums.ApplicationTypes, formData.ApplicationType) }}
                        </div>
                        <div class="font-size-12 smaller-line-height ml-8">Serial No.: <span class="font-italic">{{ formData.Id? formData.Id: ''}}</span></div>
                        <div class="font-size-12 smaller-line-height ml-8">
                            Type: <span class="font-italic">{{ $globalHelpers.getEnumLabel($globalEnums.ExporterTypes, formData.User.ExporterType) }} {{ $globalHelpers.getApplicationTypesName(formData.ApplicationType) }}</span>
                            <div class="ml-8" v-if="formData.ApplicationType === $globalEnums.ApplicationTypes.Renewal && renewal.renewalYears > 0">
                                <span class="mr-2">{{ renewal.renewalYears + ' Year' + (renewal.renewalYears > 1? 's': '')}}</span>
                                <span class="text-underline mr-2">{{ renewal.lateYears? 'Late ' + renewal.lateYears + ' Year' + (renewal.lateYears > 1? 's': ''): '' }}</span> <span class="text-underline">{{ renewal.advanceYears? 'Advance ' + renewal.advanceYears + ' Year' + (renewal.advanceYears > 1? 's': ''): '' }}</span>
                            </div>
                        </div>
                        <div class="font-size-12 smaller-line-height ml-8">Created: <span class="font-italic">{{ $globalHelpers.getFormattedDate(formData.Created, '', 'DD/MM/YYYY') }}</span> | Updated: <span class="font-italic">{{ $globalHelpers.getFormattedDate(formData.Modified, '', 'DD/MM/YYYY') }}</span></div>
                        <div class="font-size-12 smaller-line-height ml-8">Status: <span class="font-italic">{{ $globalHelpers.getApplicationStatusName(formData.StatusId) }}</span></div>
                        <div class="font-size-12 smaller-line-height ml-8">
                            <v-btn class="default mt-2" v-if="$globalHelpers.getAuthStoreValue('user_username') === 'iia-bd'" :disabled="loading" small @click="formActions.viewAdminEAUpdateModal = true"><v-icon small>fa-pen-to-square</v-icon> Update App. Data</v-btn>
                        </div>

                    </v-col>
                    <v-col cols="12" sm="8" class="text-right">
                        <div class="mb-2">{{ formData.User.OrganizationName }} <span v-if="formData.User.Username" class="font-size-14">({{ formData.User.Username }})</span></div>
                        <div class="smaller-line-height mb-2 font-size-12" v-if="formData.ExporterValidTillDate">Registration Valid Till: {{ $globalHelpers.getFormattedDate(formData.ExporterValidTillDate, '', 'MMMM D, YYYY') }}</div>
                        <span v-if="formData.StatusId !== $globalEnums.ApplicationStatuses.New">
                            <v-btn type="button" class="ml-1 py-3" small color="primary" :disabled="loading" v-for="(allowedStatus, i) in formActions.allowedStatuses" :key="i" @click="prepareStatusChange(allowedStatus.id)">
                                <v-icon x-small class="mr-1" v-if="allowedStatus.iconLeft">{{ allowedStatus.icon }}</v-icon>
                                {{ allowedStatus.label }}
                                <v-icon x-small class="ml-1" v-if="!allowedStatus.iconLeft">{{ allowedStatus.icon }}</v-icon>
                            </v-btn>
                        </span>

                        <span v-if="formData.StatusId === $globalEnums.ApplicationStatuses.Approved && formData.ApplicationType !== $globalEnums.ApplicationTypes.Address_Change">
                            <v-btn type="button" class="ml-1 py-3" small color="warning" :disabled="loading" @click="pdfCertificate">Print Certificate <v-icon small class="ml-1">fas fa-certificate</v-icon></v-btn>
                        </span>
                        <!--<span v-if="formData.StatusId >= $globalEnums.ApplicationStatuses.Inspection && $globalHelpers.getAuthStoreValue('user_role_id') !== $globalEnums.roles.Exporter">
                            <v-btn type="button" class="ml-1 py-3" small color="primary" :disabled="loading" @click="navigateToInspection">Inspection Report <v-icon small class="ml-1">fas fa-industry</v-icon></v-btn>
                        </span>-->
                    </v-col>
                </v-row>
            </v-card-title>
            <v-divider class="divider-inner-page"></v-divider>
            <v-card-text style="padding-top: 12px; padding-bottom: 12px;">
                <validation-observer ref="observerMain" v-slot="{ invalid }">
                    <v-row>
                        <v-col sm="3" class="border-right-grey">
                            <v-list dense>
<!--                                <v-list-item-group color="primary"></v-list-item-group>-->
                                <v-list-item @click="selectSection(100)" :class="{'v-item--active v-list-item--active v-list-item--link theme--light': selectedSectionId === 100 }">
                                    <v-list-item-icon>
                                        <v-icon small>fas fa-clock-rotate-left</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content><v-list-item-title>Status History</v-list-item-title></v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="selectSection(101)" :class="{'v-item--active v-list-item--active v-list-item--link theme--light': selectedSectionId === 101, '': selectedSectionId !== 101 }">
                                    <v-list-item-icon>
                                        <v-icon small v-if="!invalid && formActions.selectedHSCodes.length > 0" class="success--text">fas fa-circle-check</v-icon>
                                        <v-icon small v-else>fas fa-file-pen</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content><v-list-item-title>Form <span class="error--text">*</span></v-list-item-title></v-list-item-content>
                                </v-list-item>
                                <div v-for="(attachment, i) in attachments" :key="i">
                                    <v-list-item @click="selectSection(attachment.id)" :class="{'v-item--active v-list-item--active v-list-item--link theme--light': selectedSectionId === attachment.id }">
                                        <v-list-item-icon>
                                            <v-icon small v-if="isAttachmentSectionComplete(attachment.id)" class="success--text">fas fa-circle-check</v-icon>
                                            <v-icon small v-else>far fa-circle</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ attachment.label}} <span v-if="attachment.required" class="error--text">*</span></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </div>

                                <v-list-item @click="selectSection(102)" v-if="formData.ApplicationType !== $globalEnums.ApplicationTypes.Address_Change" :class="{'v-item--active v-list-item--active v-list-item--link theme--light': selectedSectionId === 102 }">
                                    <v-list-item-icon>
                                        <v-icon small v-if="formData.PaymentStatus == 'success'" class="success--text">fas fa-circle-check</v-icon>
                                        <v-icon small v-else>fas fa-credit-card</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content><v-list-item-title>Payment <span class="error--text">*</span></v-list-item-title></v-list-item-content>
                                </v-list-item>
<!--                                <v-list-item @click="selectSection(103)" :class="{'v-item&#45;&#45;active v-list-item&#45;&#45;active v-list-item&#45;&#45;link theme&#45;&#45;light': selectedSectionId === 103 }">
                                    <v-list-item-icon>
&lt;!&ndash;                                        <v-icon small v-if="formData.PaymentStatus == 'success'" class="success&#45;&#45;text">fas fa-circle-check</v-icon>&ndash;&gt;
                                        <v-icon small>far fa-comment-alt</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content><v-list-item-title>Exporter Note</v-list-item-title></v-list-item-content>
                                </v-list-item>-->
                            </v-list>
                        </v-col>
                        <v-col sm="9">
                            <div v-show="selectedSectionId === 100">
                                <v-row>
                                    <v-col cols="12" sm="6"><div class="font-size-20 font-bold">Status History and Notes</div></v-col>
                                    <v-col cols="12" sm="6" v-if="$globalHelpers.getAuthStoreValue('user_role_id') !== $globalEnums.roles.Exporter"><v-btn type="button" class="py-3 ml-1 float-end" small color="secondary" outlined @click="pdfNotes" :disabled="loading"><v-icon small class="mr-1">fas fa-print</v-icon> Print Notes</v-btn></v-col>
                                </v-row>
                                <v-divider class="mt-2"></v-divider>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                        <tr>
                                            <th class="text-left" width="15%">Date</th>
                                            <th class="text-left" width="20%">Status</th>
                                            <th class="text-left" width="20%">User</th>
                                            <th class="text-left" width="25%">Organization</th>
                                            <!--<th class="text-left" v-if="$globalHelpers.getAuthStoreValue('user_role_id') !== $globalEnums.roles.Exporter">Note</th>-->
                                            <th class="text-left">{{ $globalHelpers.getAuthStoreValue('user_role_id') !== $globalEnums.roles.Exporter? 'Note': '' }}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(eaStatusLog, i) in formData.EAStatusLogs" :key="i">
                                            <td>{{ $globalHelpers.getFormattedDate(eaStatusLog.Created, '', 'MMMM D, YYYY') }}<br>{{ $globalHelpers.getFormattedDate(eaStatusLog.Created, '', 'hh:mm:ss A') }}</td>
                                            <td>{{ $globalHelpers.humanize($globalHelpers.getApplicationStatusName(eaStatusLog.StatusId)) }}</td>
                                            <td>
                                                {{ eaStatusLog.CreatedByUser.Name }}
                                                <div v-if="eaStatusLog.CreatedByUser.DesignationId" class="font-size-12 font-italic">{{ $globalHelpers.getUserDesignationName(eaStatusLog.CreatedByUser.DesignationId) }}</div>
                                            </td>
                                            <td>{{ eaStatusLog.CreatedByUser.OrganizationName }}</td>
                                            <!--<td v-if="$globalHelpers.getAuthStoreValue('user_role_id') !== $globalEnums.roles.Exporter">{{ eaStatusLog.Comment }}</td>-->
                                            <td>
                                                <div v-if="eaStatusLog.StatusId === $globalEnums.ApplicationStatuses.New">
                                                    <v-btn type="button" class="ml-1 py-3" small color="primary" :disabled="loading" @click="showNoteModal(formData.ExporterNote)" v-if="formData.ExporterNote"><v-icon small class="mr-1">fas fa-magnifying-glass</v-icon> View Exporter Note</v-btn>
                                                </div>
                                                <div v-if="$globalHelpers.getAuthStoreValue('user_role_id') !== $globalEnums.roles.Exporter">
                                                    <v-btn type="button" class="ml-1 py-3" small color="primary" :disabled="loading" @click="showNoteModal(eaStatusLog.Comment)" v-if="eaStatusLog.Comment && eaStatusLog.Comment.length >= 200"><v-icon small class="mr-1">fas fa-magnifying-glass</v-icon> View Note</v-btn>
                                                    <span v-else-if="eaStatusLog.Comment && eaStatusLog.Comment.length < 200">{{ eaStatusLog.Comment }}</span>
                                                </div>
                                                <div v-if="eaStatusLog.StatusId === $globalEnums.ApplicationStatuses.Team_Approval">
                                                    <ol>
                                                        <li v-for="(inspector, i) in getStatusInspection(eaStatusLog.EAInspectionId)" :key="i">
                                                            <div>{{ inspector.User.Name + (inspector.isLeader? ', (Lead)': '') }}</div>
                                                            <div v-if="inspector.User.DesignationId">{{ $globalHelpers.getUserDesignationName(inspector.User.DesignationId) }}</div>
                                                            <div>{{ $globalHelpers.getExporterTypesName(inspector.User.ExporterType) }}</div>
                                                        </li>
                                                    </ol>
<!--                                                    <div v-for="(inspector, i) in getStatusInspection(eaStatusLog.EAInspectionId)" :key="i">
                                                        {{ inspector.User.Name + ' (' + $globalHelpers.getUserRoleName(inspector.User.RoleId) + (inspector.isLeader? ', Lead': '') + ') ' }}
                                                    </div>-->
                                                </div>
                                                <v-btn type="button" class="ml-1 py-3" small color="primary" :disabled="loading" @click="navigateToInspection(eaStatusLog.EAInspectionId)" v-if="eaStatusLog.StatusId === $globalEnums.ApplicationStatuses.Inspection"><v-icon small class="mr-1">fas fa-industry</v-icon> Inspection Report</v-btn>
                                                <v-btn type="button" class="ml-1 mt-2" small color="primary" :disabled="loading" @click="navigateToInspection(eaStatusLog.EAInspectionId)" v-if="eaStatusLog.StatusId === $globalEnums.ApplicationStatuses.Approved && eaStatusLog.EAInspectionId && $globalHelpers.getAuthStoreValue('user_username') === 'iia-bd'"><v-icon small class="mr-1">fas fa-industry</v-icon> Auto Inspection Report</v-btn>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                            <div v-show="selectedSectionId === 101">
                                <v-row>
                                    <v-col cols="12" sm="6"><div class="font-size-20 font-bold">Company Details</div></v-col>
                                    <v-col cols="12" sm="6"><v-btn type="button" class="py-3 ml-1 float-end" small color="secondary" outlined @click="pdfApplication" :disabled="loading"><v-icon small class="mr-1">fas fa-print</v-icon> Print Application</v-btn></v-col>
                                </v-row>
                                <v-divider class="mt-2 mb-5"></v-divider>
                                <v-form @submit.prevent="save" :disabled="!formActions.formEditEnabled" ref="companyInfoForm">
                                    <v-row>
                                        <v-col cols="12" sm="3">
                                            <validation-provider v-slot="{ errors }" name="TIN No" rules="required|length:12|numeric">
                                                <v-text-field v-model="formData.TINNo" type="text" :error-messages="errors" label="Tin No *" :disabled="loading" required hint="ex. 123456789012"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <validation-provider v-slot="{ errors }" name="BIN No" rules="required">
                                                <v-text-field v-model="formData.BINNo" type="text" :error-messages="errors" label="Bin No *" :disabled="loading" required></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3" v-if="formData.ApplicationType !== $globalEnums.ApplicationTypes.Registration">
                                            <validation-provider v-slot="{ errors }" name="Rex No">
                                                <v-text-field v-model="formData.RexNo" type="text" :error-messages="errors" label="Rex No" :disabled="loading"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <validation-provider v-slot="{ errors }" name="Service Office" rules="required">
                                                <v-select v-model="formData.ServiceOfficeId" :items="$globalHelpers.enumToArray($globalEnums.ServiceOffices)" item-value="id" item-text="label" label="Service Office *" :error-messages="errors" :disabled="loading"></v-select>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>


                                    <div class="mt-10">Office Information</div>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <validation-provider v-slot="{ errors }" name="OfficeAddress" rules="required">
                                                <v-text-field v-model="formData.OfficeAddress" type="text" :error-messages="errors" label="Address *" :disabled="loading" required></v-text-field>
                                            </validation-provider>
                                            <validation-provider v-slot="{ errors }" name="OfficeEmail" rules="required|email">
                                                <v-text-field v-model="formData.OfficeEmail" type="text" :error-messages="errors" label="Email *" :disabled="loading" required></v-text-field>
                                            </validation-provider>
                                            <validation-provider v-slot="{ errors }" name="OfficePhone" rules="required">
                                                <v-text-field v-model="formData.OfficePhone" type="text" :error-messages="errors" label="Phone *" :disabled="loading" required></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <validation-provider v-slot="{ errors }" name="District" rules="required">
                                                <v-select v-model="formData.OfficeDistrictId" :items="$globalSettings.districts" item-value="id" item-text="label" label="District *" :error-messages="errors" :disabled="loading"></v-select>
                                            </validation-provider>
                                            <validation-provider v-slot="{ errors }" name="District" rules="required">
                                                <v-select v-model="formData.OfficeThanaId" :items="$globalSettings.thanas.filter(x => x.districtId === formData.OfficeDistrictId)" item-value="id" item-text="label" label="Thana *" :error-messages="errors" :disabled="loading"></v-select>
                                            </validation-provider>
                                            <v-text-field v-model="formData.OfficeWebsite" type="text" label="Website" :disabled="loading"></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row class="mt-10">
                                        <v-col cols="12" sm="6">
                                            <div>Factory Information</div>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-btn depressed color="primary" @click="formCopyOfficeToFactory" x-small v-if="formActions.formEditEnabled"><v-icon small x-small class="mr-2">far fa-copy</v-icon>Copy from Office</v-btn>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <validation-provider v-slot="{ errors }" name="FactoryAddress" rules="required">
                                                <v-text-field v-model="formData.FactoryAddress" type="text" :error-messages="errors" label="Address *" :disabled="loading" required></v-text-field>
                                            </validation-provider>
                                            <validation-provider v-slot="{ errors }" name="FactoryEmail" rules="required|email">
                                                <v-text-field v-model="formData.FactoryEmail" type="text" :error-messages="errors" label="Email *" :disabled="loading" required></v-text-field>
                                            </validation-provider>
                                            <validation-provider v-slot="{ errors }" name="FactoryPhone" rules="required">
                                                <v-text-field v-model="formData.FactoryPhone" type="text" :error-messages="errors" label="Phone *" :disabled="loading" required></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <validation-provider v-slot="{ errors }" name="District" rules="required">
                                                <v-select v-model="formData.FactoryDistrictId" :items="$globalSettings.districts" item-value="id" item-text="label" label="District *" :error-messages="errors" :disabled="loading"></v-select>
                                            </validation-provider>
                                            <validation-provider v-slot="{ errors }" name="District" rules="required">
                                                <v-select v-model="formData.FactoryThanaId" :items="$globalSettings.thanas.filter(x => x.districtId === formData.FactoryDistrictId)" item-value="id" item-text="label" label="Thana *" :error-messages="errors" :disabled="loading"></v-select>
                                            </validation-provider>
                                            <v-text-field v-model="formData.FactoryWebsite" type="text" label="Website" :disabled="loading"></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row class="mt-5" v-if="formData.ApplicationType !== $globalEnums.ApplicationTypes.Address_Change">
                                        <v-col cols="12" :sm="formActions.formEditEnabled ? 6: 12">
                                            Products/HS Codes
                                            <div class="mt-2" style="min-height: 300px;">
                                                <v-row v-for="(hsCode, i) in formActions.selectedHSCodes" :key="i">
                                                    <v-col sm="2">
                                                        {{ hsCode.Code }}
                                                    </v-col>
                                                    <v-col sm="8">
                                                        {{ hsCode.Description }}
                                                    </v-col>
                                                    <v-col sm="2" v-if="formActions.formEditEnabled">
                                                        <v-btn fab x-small type="button" class="mr-3" :disabled="loading" @click="removeHSCode(hsCode)"><v-icon x-small>fas fa-minus</v-icon></v-btn>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-if="formActions.formEditEnabled">
                                            <v-text-field v-model="formActions.hscodeSearchKeyword" type="text" label="Search & Add HS code" @keyup="searchHSCode"></v-text-field>
                                            <div class="mt-2" style="min-height: 300px;">
                                                <v-row v-for="(hsCode, i) in formActions.hsCodeSearchResults" :key="i">
                                                    <v-col sm="2">
                                                        {{ hsCode.Code }}
                                                    </v-col>
                                                    <v-col sm="8">
                                                        {{ hsCode.Description }}
                                                    </v-col>
                                                    <v-col sm="2">
                                                        <v-btn fab x-small type="button" class="mr-3" :disabled="loading" @click="addHSCode(hsCode)"><v-icon x-small>fas fa-plus</v-icon></v-btn>
                                                    </v-col>
                                                </v-row>
                                                <i v-if="formActions.hscodeSearchKeyword && formActions.hsCodeSearchResults.length < 1">No HS Code matched your keyword.</i>
                                            </div>
                                        </v-col>

                                    </v-row>

                                    <v-row class="mt-10" v-if="formActions.formEditEnabled">
                                        <v-col cols="12" sm="12">
                                            <div>Other Information</div>
                                            <v-textarea outlined rows="3" v-model="formData.ExporterNote" label="Exporter Note" hint="If you have any comment to make, please write it down here" class="mt-5"></v-textarea>
                                        </v-col>
                                    </v-row>

                                    <v-card-actions class="d-flex flex-row-reverse">
                                        <v-btn v-if="formData.ApplicationType === $globalEnums.ApplicationTypes.Address_Change && formActions.formEditEnabled && !invalid && isRequiredAttachmentsUploaded" type="button" class="success" :disabled="invalid || loading" @click="prepareStatusChange($globalEnums.ApplicationStatuses.Director_desk)">Submit Application</v-btn>
                                        <v-btn v-if="formActions.formEditEnabled" type="submit" class="secondary mr-3" :disabled="invalid || loading">Save</v-btn>
                                    </v-card-actions>
                                </v-form>
                            </div>
                            <div v-show="selectedSectionId === 102">
                                <v-row>
                                    <v-col cols="12" sm="6"><div class="font-size-20 font-bold">Payment</div></v-col>
                                    <v-col cols="12" sm="6"><v-btn type="button" class="py-3 ml-1 float-end" small color="secondary" @click="pdfPayment" :disabled="loading" v-if="formData.PaymentStatus"><v-icon small>fas fa-print</v-icon></v-btn></v-col>
                                </v-row>
                                <v-divider class="mt-2 mb-5"></v-divider>
                                <div v-if="formActions.formEditEnabled && formActions.allowedPaymentOption">
                                    <div class="font-italic" v-if="!isRequiredAttachmentsUploaded">Please upload all the required (*) attachments to make payment!</div>
                                    <div class="font-italic" v-else-if="invalid || formActions.selectedHSCodes.length < 1">Please complete the Form section to make payment!</div>
                                    <div v-else>
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-card>
                                                    <v-card-title>Pay with EPB Payslip</v-card-title>
                                                    <v-card-text>
                                                        <v-row v-if="formData.ApplicationType === $globalEnums.ApplicationTypes.Renewal">
                                                            <v-col cols="12" sm="6">
                                                                <v-row class="mt-3">
                                                                    <v-col cols="12" sm="6">Total Years:</v-col>
                                                                    <v-col cols="12" sm="6"><span class="font-size-18">{{ renewal.renewalYears }}</span></v-col>
                                                                </v-row>
                                                            </v-col>
                                                            <v-col cols="12" sm="6">
                                                                <v-text-field v-model="renewal.lateYears" label="Late Year(s)" hint="Late years for Renewal" v-if="renewal.type === 1" disabled></v-text-field>
                                                                <v-select v-model="renewal.advanceYears" :items="numberOfYears" @change="prepareRenewalYears" label="Advance Renewal Year(s)" hint="Number of advance years for Renewal" :disabled="loading"></v-select>
                                                            </v-col>
                                                        </v-row>

                                                        <v-text-field v-model="formActions.payslipExporterNo" :label="formActions.payslipTemporaryExporter? 'Temporary Exporter No': 'Exporter No'" :hint="formActions.payslipTemporaryExporter? 'i.e. NRBD-00001': ''" :disabled="loading || !formActions.payslipTemporaryExporter"></v-text-field>
                                                        <v-text-field v-model="formActions.payslipNumber" label="EPB Payslip Number" hint="i.e. PSTX-12345678912345" :disabled="loading"></v-text-field>

                                                        <div class="text-right">
                                                            <v-btn type="button" class="secondary mr-3" :disabled="!formActions.payslipNumber || !formActions.payslipExporterNo || loading" @click="checkPayslip">Submit <v-icon dark>fas fa-arrow-right</v-icon></v-btn>
                                                        </div>
                                                    </v-card-text>
                                                </v-card>

                                            </v-col>
                                            <!--<v-col cols="12" sm="6" v-if="formData.ApplicationType === $globalEnums.ApplicationTypes.Registration">-->
                                            <v-col cols="12" sm="6">
                                                <v-card>
                                                    <v-card-title>Pay Online with Card/Mobile Banking</v-card-title>
                                                    <v-card-text>
                                                        <v-row v-if="formData.ApplicationType === $globalEnums.ApplicationTypes.Renewal">
                                                            <v-col cols="12" sm="6">
                                                                <v-row class="mt-3">
                                                                    <v-col cols="12" sm="6">Total Years:</v-col>
                                                                    <v-col cols="12" sm="6"><span class="font-size-18">{{ renewal.renewalYears }}</span></v-col>
                                                                </v-row>
                                                            </v-col>
                                                            <v-col cols="12" sm="6">
                                                                <v-text-field v-model="renewal.lateYears" label="Late Year(s)" hint="Late years for Renewal" v-if="renewal.type === 1" disabled></v-text-field>
                                                                <v-select v-model="renewal.advanceYears" :items="numberOfYears" @change="prepareRenewalYears" label="Advance Renewal Year(s)" hint="Number of advance years for Renewal" :disabled="loading"></v-select>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col cols="12" sm="12" class="text-center">
                                                                <v-btn type="button" @click="requestSPG" :disabled="loading"><img src="images/pay-bkash.png" class="mr-2" /> <img src="images/pay-visa.png" class="mr-2" /> <img src="images/pay-master.png" class="mr-2" /> Pay Online</v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>

                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>
                                <div v-else class="font-italic error--text">{{ formActions.paymentErrorMsg }}</div>

                                <v-card elevation="1" v-if="formData.PaymentStatus" class="mt-5" max-width="500">
                                    <v-card-title>Payment Details</v-card-title>
                                    <v-card-text>
                                        <v-simple-table>
                                            <template v-slot:default>
                                                <tbody>
                                                <tr>
                                                    <td>Status</td><td :class="{'red--text': formData.PaymentStatus !== 'success'}">{{ formData.PaymentStatus }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Year(s)</td><td>{{ formData.PaymentYears }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Method</td><td>{{ $globalHelpers.getPaymentInstrumentName(formData.PaymentInstrument) }}</td>
                                                </tr>
                                                <tr>
                                                    <td>TransactionId</td><td>{{ formData.PaymentTransactionId }}</td>
                                                </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card-text>
                                </v-card>

                            </div>
                            <div v-show="selectedSectionId < 100">
                                <div class="font-size-20 font-bold">{{ currentAttachmentItem.label }}</div>
                                <v-divider class="mt-2 mb-5"></v-divider>

                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field v-model="currentAttachmentData.attachmentDataNumber" v-if="currentAttachmentItem.numberRequired" type="text" label="Number" :disabled="!formActions.formEditEnabled" prepend-icon="fas fa-hashtag"></v-text-field>

                                        <!--<v-text-field v-model="currentAttachmentData.attachmentDataDate" v-if="currentAttachmentItem.dateRequired" type="date" label="Date" :disabled="!formActions.formEditEnabled" prepend-icon="fas fa-calendar-days"></v-text-field>-->
                                        <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" v-if="currentAttachmentItem.dateRequired">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field :value="currentAttachmentData.attachmentDataDate && $globalHelpers.getFormattedDate(currentAttachmentData.attachmentDataDate, '', 'MMMM D, YYYY')" label="Date" readonly v-bind="attrs" v-on="on" :disabled="!formActions.formEditEnabled" clearable @click:clear="currentAttachmentData.attachmentDataDate = null" prepend-icon="fa-regular fa-calendar"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="currentAttachmentData.attachmentDataDate"></v-date-picker>
                                        </v-menu>


                                        <v-file-input v-if="formActions.formEditEnabled" show-size :clearable="true" truncate-length="100" :label="currentAttachmentItem.formatLabel + '. Max 1 Megabyte'" :accept="currentAttachmentItem.formats" @change="uploadImage" v-model="attachment.attachmentInputFileName" ref="attachmentInput"></v-file-input>
                                    </v-col>
                                    <v-col cols="12" sm="6">

                                    </v-col>
                                </v-row>

                                <div class="images mt-10" v-viewer="{movable: false}" v-for="item in uploadedImageAttachments" v-bind:key="item.id" v-show="selectedSectionId === item.Type">
                                    <div class="attachmentImageContainer">
                                        <img v-if="item.MimeType !== 'application/pdf'" :aspect-ratio="16/9" :src="$apiBaseURL + $globalSettings.api.endpoints.media.attachments + item.FilePath" class="attachmentImage">
                                        <div>
                                            <v-btn v-if="formActions.formEditEnabled" type="button" fab x-small class="error pl-1 pr-0" @click="deleteAttachment(item.Id)"><v-icon small>fas fa-minus</v-icon></v-btn></div>
                                    </div>

                                </div>
                                <div class="clearfix"></div>
                                <v-row class="mt-10">
                                    <v-col v-for="attachment in uploadedPdfAttachments" v-bind:key="attachment.id" v-show="selectedSectionId === attachment.Type" class="attachment attachmentPdfContainer">
                                        <iframe :src="$apiBaseURL + $globalSettings.api.endpoints.media.attachments + attachment.FilePath" width="100%" height="500"></iframe>
                                        <v-btn v-if="formActions.formEditEnabled" type="button" fab x-small class="error pl-1 pr-0" @click="deleteAttachment(attachment.Id)"><v-icon small>fas fa-minus</v-icon></v-btn>
                                    </v-col>
                                </v-row>

                            </div>
<!--                            <div v-show="selectedSectionId === 103">
                                <v-row>
                                    <v-col cols="12" sm="6"><div class="font-size-20 font-bold">Exporter Note</div></v-col>
                                </v-row>
                                <v-divider class="mt-2 mb-5"></v-divider>
                                <v-textarea outlined v-model="formData.ExporterNote" label="Note" hint="If you have any comment to make, please write it down here"></v-textarea>
                            </div>-->
                        </v-col>

                    </v-row>

                </validation-observer>
            </v-card-text>
        </v-card>

        <v-dialog v-model="formActions.viewStatusChangeModal" width="500">
            <validation-observer ref="observerStatusChange" v-slot="{ invalid }">
                <v-form @submit.prevent="changeStatus">
                    <v-card>
                        <v-card-title class="grey lighten-2">
                            Change status to {{ $globalHelpers.humanize($globalHelpers.getApplicationStatusName(formActions.nextStatusId)) }}
                        </v-card-title>

                        <v-card-text class="pt-2">
<!--                            <validation-provider v-slot="{ errors }" name="Enrolment No" :rules="{required: formActions.nextStatusId === $globalEnums.ApplicationStatuses.Approved, min: 5, min_value: 1, numeric: true}" v-if="formActions.nextStatusId === $globalEnums.ApplicationStatuses.Approved && !formData.User.Username">
                                <v-text-field v-model="formActions.statusChangeUsername" type="text" :prefix="getUsernamePrefix()" :error-messages="errors" label="Registration ID *" :disabled="loading" required></v-text-field>
                            </validation-provider>-->
<!--                            <validation-provider v-slot="{ errors }" name="BIN No" :rules="{required: true, min: 9}" v-if="formActions.generateAutomaticInspection">
                                <v-text-field v-model="formActions.statusChangeBinNo" type="text" :error-messages="errors" label="BIN No *" :disabled="loading" required></v-text-field>
                            </validation-provider>
                            <validation-provider v-slot="{ errors }" name="TIN No"  rules="required|length:12|numeric" v-if="formActions.generateAutomaticInspection">
                                <v-text-field v-model="formActions.statusChangeTinNo" type="text" :error-messages="errors" label="TIN No *" :disabled="loading" required></v-text-field>
                            </validation-provider>
                            <validation-provider v-slot="{ errors }" name="Factory Type" :rules="{required: true}" v-if="formActions.generateAutomaticInspection">
                                <div class="font-size-16 mb-2">Factory Type:</div>
                                <v-row>
                                    <v-col cols="12" sm="3" class="py-0" v-for="item in factoryTypes" v-bind:key="item.Id">
                                        <v-checkbox v-model="formActions.statusChangeFactoryTypes" :label="item.Label" :value="item.Id" dense></v-checkbox>
                                    </v-col>
                                </v-row>
                                <div class="red&#45;&#45;text">{{ errors[0] }}</div>
                            </validation-provider>-->
                            <validation-provider v-slot="{ errors }" name="Note" :rules="{required: formActions.nextStatusId !== $globalEnums.ApplicationStatuses.Approved}">
                                <v-textarea v-model="formActions.statusChangeComment" :error-messages="errors" :label="'Note' + (formActions.nextStatusId === $globalEnums.ApplicationStatuses.Rejected? ' *': ' (Optional)')" rows="5" auto-grow :disabled="loading"></v-textarea>
                            </validation-provider>

                            <v-expansion-panels v-model="formActions.status_change_msg_acdn_open" v-if="$globalHelpers.getAuthStoreValue('user_role_id') !== $globalEnums.roles.Exporter">
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        Templates
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content >
                                        <ul>
                                            <li v-for="(msg, i) in $globalSettings.ea_status_change_messages" :key="i" @click="insertStatusChangeNote(msg)" style="cursor: pointer">{{ msg }}</li>
                                        </ul>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn type="submit" class="success" :disabled="invalid">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </validation-observer>
        </v-dialog>


        <v-dialog v-model="formActions.viewInspectionModal" width="800" scrollable>
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    {{ formActions.inspectionModalTitle }}
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6" class="mt-5">
                            <div class="mt-2 font-italic">Select Officers</div>
                            <div class="mt-3">
                                <v-text-field label="Search" placeholder="Start typing to search" outlined dense v-model="formActions.searchInspectionOfficerKeyword" @keyup="searchInspectionOfficer"></v-text-field>
                            </div>
                            <div style="height: 400px; overflow-y: scroll;">
                                <div v-for="inspector in formActions.filteredInspectors" v-bind:key="inspector.Id">
                                    <v-checkbox v-model="formActions.selectedInspectors" :value="inspector" @click="formActions.selectedTeamLeader = null">
                                        <template v-slot:label>

                                            <v-row>
                                                <v-col cols="12" sm="2" class="pt-4">
                                                    <v-img :src="getSearchInspectionOfficerProfilePic(inspector)" width="40" />
                                                </v-col>
                                                <v-col cols="12" sm="10">
                                                    {{ inspector.Name }}
                                                    <div class="font-size-14">{{ (inspector.DesignationId? $globalHelpers.getUserDesignationName(inspector.DesignationId) + ', ': '') + (inspector.ServiceOfficeId === $globalEnums.ServiceOffices.Dhaka? $globalHelpers.getExporterTypesName(inspector.ExporterType) + ', ': '' ) + $globalHelpers.getServiceOfficeName(inspector.ServiceOfficeId) }}</div>
                                                </v-col>
                                            </v-row>

                                        </template>
                                    </v-checkbox>
                                    <div class="ml-8">

                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" class="mt-5">
                            <div class="mt-2 font-italic">Select Team Leader</div>
                            <div class="mt-3" style="height: 465px; overflow-y: scroll;">
                                <div v-for="inspector in formActions.selectedInspectors" v-bind:key="inspector.Id">
                                    <v-radio-group v-model="formActions.selectedTeamLeader">
                                        <v-radio :label="inspector.Name" :value="inspector.Id">
                                            <template v-slot:label>

                                                <v-row>
                                                    <v-col cols="12" sm="2" class="pt-4">
                                                        <v-img :src="getSearchInspectionOfficerProfilePic(inspector)" width="40" />
                                                    </v-col>
                                                    <v-col cols="12" sm="10">
                                                        {{ inspector.Name }}
                                                        <div class="font-size-14">{{ (inspector.DesignationId? $globalHelpers.getUserDesignationName(inspector.DesignationId) + ', ': '') + $globalHelpers.getExporterTypesName(inspector.ExporterType) }}</div>
                                                    </v-col>
                                                </v-row>

                                            </template>
                                        </v-radio>
                                    </v-radio-group>
<!--                                    <div class="ml-8">{{ ' (' + $globalHelpers.getUserDesignationName(inspector.DesignationId) + ', '+ $globalHelpers.getExporterTypesName(inspector.ExporterType) +')' }}</div>-->
                                </div>
                            </div>

                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn type="button" class="success" :disabled="formActions.selectedInspectors.length < 1 || !formActions.selectedTeamLeader" @click="changeStatus">{{ formData.StatusId === $globalEnums.ApplicationStatuses.Team_Approval? 'Approve': 'Save' }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="formActions.noteModal.show" width="600">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Note
                </v-card-title>

                <v-card-text class="pt-5">
                    {{ formActions.noteModal.note }}
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn type="button" class="secondary" small @click="formActions.noteModal.show = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="formActions.viewExporterUpdateModal" width="800">
            <validation-observer ref="observerStatusChange" v-slot="{ invalid }">
                <v-form @submit.prevent="updateExporter">
                    <v-card>
                        <v-card-title class="grey lighten-2">
                            Update Exporter
                        </v-card-title>

                        <v-card-text class="pt-2">
                            <validation-provider v-slot="{ errors }" name="Enrolment No" rules="required|min:5|min_value:1|numeric" v-if="!formData.User.Username">
                                <v-text-field v-model="formActions.statusChangeUsername" type="text" :prefix="getUsernamePrefix()" :error-messages="errors" label="Registration ID *" :disabled="loading" required></v-text-field>
                            </validation-provider>
                            <validation-provider v-slot="{ errors }" name="BIN No" :rules="{required: true, min: 9}" v-if="!certificateBINNo">
                                <v-text-field v-model="formActions.statusChangeBinNo" type="text" :error-messages="errors" label="BIN No *" :disabled="loading" required></v-text-field>
                            </validation-provider>
                            <validation-provider v-slot="{ errors }" name="TIN No"  rules="required|length:12|numeric" v-if="!certificateTINNo">
                                <v-text-field v-model="formActions.statusChangeTinNo" type="text" :error-messages="errors" label="TIN No *" :disabled="loading" required></v-text-field>
                            </validation-provider>

                            <v-row class="mt-2">
                                <v-col cols="12" sm="3">
                                    <div class="font-size-16 mb-4 font-bold">Factory Type:</div>
                                </v-col>
                                <v-col cols="12" sm="9">
                                    <v-text-field label="Search" placeholder="Start typing to search" outlined dense v-model="formActions.searchFactoryTypeKeyword" @keyup="searchFactoryType" v-on:keydown.enter.prevent @click:clear="clearFactoryTypeSearch()" clearable></v-text-field>
                                </v-col>
                            </v-row>
                            <validation-provider v-slot="{ errors }" name="Factory Type" :rules="{required: true}" v-if="!certificateFactoryTypes">
                                <div class="red--text">{{ errors[0] }}</div>
                                <v-row>
                                    <v-col cols="12" sm="3" class="py-0" v-for="item in formActions.filteredFactoryTypes" v-bind:key="item.Id">
                                        <v-checkbox v-model="formActions.statusChangeFactoryTypes" :label="item.Label" :value="item.Id" dense></v-checkbox>
                                    </v-col>
                                </v-row>
                            </validation-provider>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn type="submit" class="success" :disabled="invalid">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </validation-observer>
        </v-dialog>

        <v-dialog v-model="formActions.viewAdminEAUpdateModal" width="400">
            <validation-observer ref="observerStatusChange" v-slot="{ invalid }">
                <v-form @submit.prevent="updateAdminEAData">
                    <v-card>
                        <v-card-title class="grey lighten-2">
                            Update Appliacation Data
                        </v-card-title>

                        <v-card-text class="pt-2">
                            <validation-provider v-slot="{ errors }" name="Late Renewal" rules="required|required">
                                <v-select v-model="formData.LateRenewal" :items="[{id: false, label: 'No'}, {id: true, label: 'Yes'}]" item-value="id" item-text="label" label="Late Renewal *" :error-messages="errors" :disabled="loading"></v-select>
                            </validation-provider>
                            <validation-provider v-slot="{ errors }" name="Payment Years" rules="required|min_value:1|numeric">
                                <v-text-field v-model="formData.PaymentYears" type="text" :error-messages="errors" label="Payment Years *" :disabled="loading"></v-text-field>
                            </validation-provider>
                            <validation-provider v-slot="{ errors }" name="Late Years" rules="required|min_value:0|numeric">
                                <v-text-field v-model="formData.LatePaymentYears" type="text" :error-messages="errors" label="Late Years *" :disabled="loading"></v-text-field>
                            </validation-provider>
                            <validation-provider v-slot="{ errors }" name="Advance Years" rules="required|min_value:0|numeric">
                                <v-text-field v-model="formData.AdvancePaymentYears" type="text" :error-messages="errors" label="Advance Years *" :disabled="loading"></v-text-field>
                            </validation-provider>

                            <validation-provider v-slot="{ errors }" name="Valid From Date" rules="">
                                <v-text-field v-model="formData.ValidFromDate" type="text" :error-messages="errors" label="Valid From Date" :disabled="loading"></v-text-field>
                            </validation-provider>

                            <validation-provider v-slot="{ errors }" name="Valid Till Date" rules="">
                                <v-text-field v-model="formData.ValidTillDate" type="text" :error-messages="errors" label="Valid Till Date" :disabled="loading"></v-text-field>
                            </validation-provider>


                            <!--<v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field :value="formData.ValidFromDate && $globalHelpers.getFormattedDate(formData.ValidFromDate, '', 'MMMM D, YYYY')" label="Valid From Date" readonly v-bind="attrs" v-on="on" clearable @click:clear="formData.ValidFromDate = null" prepend-icon="fa-regular fa-calendar"></v-text-field>
                                </template>
                                <v-date-picker v-model="formData.ValidFromDate"></v-date-picker>
                            </v-menu>

                            <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field :value="formData.ValidTillDate && $globalHelpers.getFormattedDate(formData.ValidTillDate, '', 'MMMM D, YYYY')" label="Valid Till Date" readonly v-bind="attrs" v-on="on" clearable @click:clear="formData.ValidTillDate = null" prepend-icon="fa-regular fa-calendar"></v-text-field>
                                </template>
                                <v-date-picker v-model="formData.ValidTillDate"></v-date-picker>
                            </v-menu>-->

                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn type="submit" class="success" :disabled="invalid">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </validation-observer>
        </v-dialog>

        <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="false"
                :preview-modal="true"
                :paginate-elements-by-height="1100"
                :filename="'Application #' + formData.Id"
                :pdf-quality="2"
                :manual-pagination="true"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px"

                @progress="pdfAppProgress($event)"
                @hasStartedGeneration="pdfAppStarted()"
                @hasGenerated="pdfAppCompleted($event)"
                ref="html2PdfApp"
        >
            <section slot="pdf-content" class="pdftemplate">
                <section class="pdf-item pdftemplate-heading">
                    <img src="/images/logo.png" class="mr-3">
                    <img  src="/images/epb-round.png">
                    <div class="pdftemplate-heading-title">Export Promotion Bureau</div>
                    <div class="pdftemplate-subheading">{{ formData.User.ExporterType === $globalEnums.ExporterTypes.Textile? 'Textile': 'Policy & Planning' }} Division</div>
                    <div class="pdftemplate-address">TCB Building (5th Floor), 1 Kawran Bazar, Dhaka 1215</div>
                </section>
                <section class="">
                    <div class="eapdf-body-heading-item-left">Application for Exporter {{ $globalHelpers.getEnumLabel($globalEnums.ApplicationTypes, formData.ApplicationType) }}:</div>
                    <div class="eapdf-body-heading-item-right font-size-12">Printed: <span class="font-italic">{{ $globalHelpers.getDateToday('DD/MM/YYYY h:mm:ss A') }}</span></div>
                    <div class="eapdf-body-heading-item-right font-size-12 mr-5" style="margin-right: 5px;">Status: <span class="font-italic font-bold">{{ $globalHelpers.getEnumLabel($globalEnums.ApplicationStatuses, formData.StatusId) }}</span> </div>
                    <div class="clearfix"></div>
                </section>
                <div class="pdftemplate-vertical-spacer"></div>
                <table border="1" cellspacing="0" class="eapdf-table">
                    <tr>
                        <td width="35%">Organization:</td>
                        <td>{{ formData.User.OrganizationName }} <span v-if="formData.User.Username">({{formData.User.Username}})</span></td>
                    </tr>
                    <tr>
                        <td>Office:</td>
                        <td>{{ formData.OfficeAddress }} <br> {{ formData.OfficePhone }} <br> {{ formData.OfficeEmail }}<br> {{ formData.OfficeWebsite }}</td>
                    </tr>
                    <tr>
                        <td>Factory:</td>
                        <td>{{ formData.FactoryAddress }} <br> {{ formData.FactoryPhone }} <br> {{ formData.FactoryEmail }}<br> {{ formData.FactoryWebsite }}</td>
                    </tr>
                    <tr>
                        <td>Service Office:</td>
                        <td>{{ $globalHelpers.getServiceOfficeName(formData.ServiceOfficeId) }}</td>
                    </tr>
                    <tr>
                        <td>TIN No.:</td>
                        <td>{{ formData.TINNo }}</td>
                    </tr>
                    <tr>
                        <td>BIN No.:</td>
                        <td>{{ formData.BINNo }}</td>
                    </tr>
                    <tr>
                        <td>Products:</td>
                        <td>
                            <v-row v-for="(hsCode, i) in formActions.selectedHSCodes" :key="i" style="margin-top: 0px;">
                                <v-col cols="12" sm="1">
                                    {{ hsCode.Code }}
                                </v-col>
                                <v-col cols="12" sm="9">
                                    {{ hsCode.Description }}
                                </v-col>
                            </v-row>
                        </td>
                    </tr>
                    <tr>
                        <td>Payment:</td>
                        <td>Status: {{ formData.PaymentStatus }}<br>Method: {{ $globalHelpers.getPaymentInstrumentName(formData.PaymentInstrument) }}<br>Transaction #: {{ formData.PaymentTransactionId }}</td>
                    </tr>
                </table>
                <div class="pdftemplate-vertical-spacer"></div>
                <div>Attachments:</div>
                <table border="1" cellspacing="0" class="eapdf-table">
                    <tr v-for="(attachment, i) in attachmentsWithDataRequired" :key="i">
                        <td width="35%">{{ attachment.label}}</td>
                        <td>
                            <div v-if="attachment.Number"># {{ attachment.Number }}</div>
                            <div v-if="attachment.Date">Date: {{ $globalHelpers.getFormattedDate(attachment.Date, '', 'DD/MM/YYYY') }}</div>
                        </td>
                    </tr>
                </table>

                <div style="margin-top: 50px; border-bottom: 1px solid black; display: inline-block">Signature</div>
            </section>
        </vue-html2pdf>

        <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="false"
                :preview-modal="true"
                :paginate-elements-by-height="1100"
                :filename="'Notes for Application #' + formData.Id + '.pdf'"
                :pdf-quality="2"
                :manual-pagination="false"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="760px"
                :html-to-pdf-options="{margin: [10, 5, 5, 5], filename: 'Notes for Application #' + formData.Id + '.pdf'}"

                @progress="pdfAppProgress($event)"
                @hasStartedGeneration="pdfAppStarted()"
                @hasGenerated="pdfAppCompleted($event)"
                ref="html2PdfNotes"
        >
            <section slot="pdf-content" class="pdftemplate">
<!--                <section class="pdf-item pdftemplate-heading">
                    <img src="/images/logo.png" class="mr-3">
                    <img  src="/images/epb-round.png">
                    <div class="pdftemplate-heading-title">Export Promotion Bureau</div>
                    <div class="pdftemplate-subheading">{{ formData.User.ExporterType === $globalEnums.ExporterTypes.Textile? 'Textile': 'Policy & Planning' }} Division</div>
                    <div class="pdftemplate-address">TCB Building (5th Floor), 1 Kawran Bazar, Dhaka 1215</div>
                </section>-->
                <section class="pdf-item">
                    <div class="eapdf-body-heading-item-left">
                        Notes and Statuses List
                        <br>Application #: {{ formData.Id }}
                        <br>Application Type: {{ $globalHelpers.getEnumLabel($globalEnums.ApplicationTypes, formData.ApplicationType) }}
                        <br>Department: {{ $globalHelpers.getEnumLabel($globalEnums.ExporterTypes, formData.User.ExporterType) }}
                        <br>Organization: <strong>{{ formData.User.OrganizationName }}</strong>
                    </div>
                    <div class="eapdf-body-heading-item-right font-size-12">Printed: <span class="font-italic">{{ $globalHelpers.getDateToday('DD/MM/YYYY h:mm:ss A') }}</span></div>
                    <div class="clearfix"></div>
                </section>
                <div class="pdftemplate-vertical-spacer border-bottom-grey-1"></div>

                <section class="pdf-item" v-for="(eaStatusLog, i) in formData.EAStatusLogs" :key="i">
                    <div v-if="eaStatusLog.CreatedByUser.RoleId !== $globalEnums.roles.Exporter" class="font-size-14 border-top-dotted-grey-1" style="padding-top: 10px;">
                        <div>
                            Note By: <span style="font-weight: bolder;">{{ eaStatusLog.CreatedByUser.Name }}</span><span style="font-style: italic;">{{ eaStatusLog.CreatedByUser.DesignationId? ', '+$globalHelpers.getUserDesignationName(eaStatusLog.CreatedByUser.DesignationId): '' }}</span>{{ eaStatusLog.CreatedByUser.ExporterType? ', '+$globalHelpers.getExporterTypesName(eaStatusLog.CreatedByUser.ExporterType): '' }}
                        </div>
                        <div>Date: {{ $globalHelpers.getFormattedDate(eaStatusLog.Created, '', 'DD/MM/YYYY') }} {{ $globalHelpers.getFormattedDate(eaStatusLog.Created, '', 'hh:mm:ss A') }}</div>
                        <div>Status: {{ $globalHelpers.humanize($globalHelpers.getApplicationStatusName(eaStatusLog.StatusId)) }}</div>
                        <div>
                            <div v-if="$globalHelpers.getAuthStoreValue('user_role_id') !== $globalEnums.roles.Exporter && eaStatusLog.Comment">
                                Note: <span class="font-size-13">{{ eaStatusLog.Comment }}</span>
                            </div>
                            <div v-if="eaStatusLog.StatusId === $globalEnums.ApplicationStatuses.Team_Approval">
                                Inspection Team:
                                <ol>
                                    <li v-for="(inspector, i) in getStatusInspection(eaStatusLog.EAInspectionId)" :key="i">
                                        <div>{{ inspector.User.Name + (inspector.isLeader? ', (Lead)': '') }}</div>
                                        <div v-if="inspector.User.DesignationId">{{ $globalHelpers.getUserDesignationName(inspector.User.DesignationId) }}</div>
                                        <div>{{ $globalHelpers.getExporterTypesName(inspector.User.ExporterType) }}</div>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div style="height: 50px;">

                        </div>
                    </div>
                </section>
                <div class="pdftemplate-vertical-spacer"></div>
            </section>
        </vue-html2pdf>

        <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="false"
                :preview-modal="true"
                :paginate-elements-by-height="1100"
                :filename="'payment receipt EA #' + formData.Id"
                :pdf-quality="2"
                :manual-pagination="true"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px"

                @progress="pdfAppProgress($event)"
                @hasStartedGeneration="pdfAppStarted()"
                @hasGenerated="pdfAppCompleted($event)"
                ref="html2PdfPay"
        >
            <section slot="pdf-content" class="pdftemplate">
                <section class="pdf-item pdftemplate-heading">
                    <img src="/images/logo.png" class="mr-3">
                    <img  src="/images/epb-round.png">
                    <div class="pdftemplate-heading-title">Export Promotion Bureau</div>
                    <div class="pdftemplate-subheading">{{ formData.User.ExporterType === $globalEnums.ExporterTypes.Textile? 'Textile': 'Policy & Planning' }} Division</div>
                    <div class="pdftemplate-address">TCB Building (5th Floor), 1 Kawran Bazar, Dhaka 1215</div>
                </section>
                <section class="">
                    <div class="eapdf-body-heading-item-left">Payment Confirmation of {{ $globalHelpers.getEnumLabel($globalEnums.ApplicationTypes, formData.ApplicationType) }}:</div>
                    <div class="eapdf-body-heading-item-right font-size-12">Printed: <span class="font-italic">{{ $globalHelpers.getDateToday('DD/MM/YYYY h:mm:ss A') }}</span></div>
                    <div class="clearfix"></div>
                </section>
                <div class="pdftemplate-vertical-spacer"></div>
                <table border="1" cellspacing="0" class="eapdf-table">
                    <tr>
                        <td width="35%">Application #:</td>
                        <td>{{ formData.Id }}</td>
                    </tr>
                    <tr>
                        <td width="35%">Exporter No: </td>
                        <td>{{formData.User.Username}}</td>
                    </tr>
                    <tr>
                        <td>Amount:</td>
                        <td>{{ formData.PaymentAmount }} TK</td>
                    </tr>
                    <tr>
                        <td>Charged:</td>
                        <td>{{ formData.PaymentCharged }} TK</td>
                    </tr>
                    <tr>
                        <td>Status:</td>
                        <td>{{ formData.PaymentStatus }}</td>
                    </tr>
                    <tr>
                        <td>Gateway:</td>
                        <td>{{ formData.PaymentMethod }}</td>
                    </tr>
                    <tr>
                        <td>Method:</td>
                        <td>{{ formData.PaymentInstrument }}</td>
                    </tr>
                    <tr>
                        <td>Transaction #:</td>
                        <td>{{ formData.PaymentTransactionId }}</td>
                    </tr>
                    <tr>
                        <td>Message:</td>
                        <td>{{ formData.PaymentMessage }}</td>
                    </tr>
                    <tr>
                        <td>Date:</td>
                        <td>{{ $globalHelpers.getFormattedDate(formData.PaymentDate, '', 'DD/MM/YYYY') }}</td>
                    </tr>
                </table>
            </section>
        </vue-html2pdf>

        <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="false"
                :preview-modal="true"
                :paginate-elements-by-height="1100"
                :filename="'Application #' + formData.Id"
                :pdf-quality="2"
                :manual-pagination="true"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px"

                @progress="pdfAppProgress($event)"
                @hasStartedGeneration="pdfAppStarted()"
                @hasGenerated="pdfAppCompleted($event)"
                ref="html2PdfCert"
        >
            <section slot="pdf-content" class="pdftemplate" v-if="formData.StatusId >= $globalEnums.ApplicationStatuses.Approved" style="line-height: 24px; font-family: Roboto, sans-serif">
                <div class="pdftemplate-cert-border">
                    <div class="pdftemplate-cert-border">
                        <div class="pdftemplate-cert-border pdftemplate-cert-bg" style="padding: 20px;">
                            <section class="pdf-item pdftemplate-heading" style="border-bottom: none;">
                                <v-row>
                                    <v-col cols="4" sm="4" style="text-align: left;" class="font-size-14 pb-0">
                                        No: <span class="font-bold">{{ $globalHelpers.getFormattedDate(formData.Created, 'YYYY-MM-DD H:mm:ss', 'DDMMYYYY') + formData.Id}}</span>
                                    </v-col>
                                    <v-col cols="4" sm="4" class="align-center pb-0">
                                        <img  src="/images/epb-round.png" style="height: 70px;">
                                    </v-col>
                                </v-row>
                                <v-row class="mt-0">
                                    <v-col cols="12" sm="2">

                                    </v-col>
                                    <v-col cols="12" sm="8">
                                        <!--                                        <div class="font-size-18">Government of the People's Republic of Bangladesh</div>-->
                                        <div class="pdftemplate-heading-title pdf-margin-top-2">Export Promotion Bureau</div>
                                        <div class="font-size-18 pdf-margin-top-2">Enrolment Certificate</div>
                                        <div class="mt-2">Reg. No: <span class="font-bold">{{ formData.User.Username }}</span></div>
                                    </v-col>
                                    <v-col cols="12" sm="2">

                                    </v-col>
                                </v-row>
                            </section>
                            <div class="pdftemplate-vertical-spacer"></div>
                            This is to Certify that <span class="font-bold">{{ formData.User.OrganizationName }}</span> has been enrolled with <span class="font-bold">Export Promotion Bureau</span>.
                            <div class="pdf-margin-top-3 pdf-margin-bottom-2">Exporter's Particulars</div>

                            <table style="width: 100%; font-size: 14px;">
                                <tr>
                                    <td>1. Organization</td><td>: <span class="font-bold">{{ formData.User.OrganizationName }}</span></td>
                                </tr>
                                <tr>
                                    <td style="width: 30%;">2. Office Address</td><td>: <span class="font-bold">{{ formData.OfficeAddress }}, {{$globalHelpers.getThanaName(formData.OfficeThanaId)}}, {{$globalHelpers.getDistrictName(formData.OfficeDistrictId)}}</span></td>
                                </tr>
                                <tr>
                                    <td>3. Factory Address</td><td>: <span class="font-bold">{{ formData.FactoryAddress }}, {{$globalHelpers.getThanaName(formData.FactoryThanaId)}}, {{$globalHelpers.getDistrictName(formData.FactoryDistrictId)}}</span></td>
                                </tr>
                                <tr>
                                    <td>4. Rex No</td><td>: <span class="font-bold" :class="{'light-grey-bg-color': !certificateRexNo}">{{ certificateRexNo? certificateRexNo: '_ _ _ _ _' }}</span></td>
                                </tr>
                                <tr>
                                    <td>5. BIN No</td><td>: <span class="font-bold" :class="{'light-grey-bg-color': !certificateBINNo}">{{ certificateBINNo? certificateBINNo: '_ _ _ _ _' }}</span></td>
                                </tr>
                                <tr>
                                    <td>6. TIN No</td><td>: <span class="font-bold" :class="{'light-grey-bg-color': !certificateTINNo}">{{ certificateTINNo? certificateTINNo: '_ _ _ _ _' }}</span></td>
                                </tr>
                                <tr>
                                    <td>7. Type of Factory</td>
                                    <td>:
                                        <span class="font-bold">
                                            <span v-if="certificateFactoryTypes">
                                                <span v-for="(eaInspectionFactoryType, i) in certificateFactoryTypes" :key="i" class="text-underline pdf-margin-right-2">{{ eaInspectionFactoryType.FactoryType.Label }}
                                                </span>
                                            </span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>8. Service Office</td><td>: <span class="font-bold">{{ $globalHelpers.getServiceOfficeName(formData.ServiceOfficeId) }}</span></td>
                                </tr>
                            </table>
                            <div class="pdf-margin-top-8">This Certificate is valid for {{ formData.PaymentYears }} Year(s), from <span class="font-bold">{{ formData.ValidFrom > 0? '01.07.' + formData.ValidFrom: $globalHelpers.getFormattedDate(formData.ValidFromDate, '', 'DD.MM.YYYY') }}</span> to <span class="font-bold">{{ formData.ValidTill > 0? '30.06.'+ formData.ValidTill: $globalHelpers.getFormattedDate(formData.ValidTillDate, '', 'DD.MM.YYYY') }}</span>. After expiry, this certificate has to be renewed for the next period.</div>

                            <div class="pdf-margin-top-10"></div>
                            <v-row class="">
                                <v-col cols="12" sm="3" class="pt-15 font-size-14">
                                    Printed on:
                                    <div class="font-size-12">{{ $globalHelpers.getDateToday('DD/MM/YYYY h:m:s A') }}</div>
                                </v-col>
                                <v-col cols="12" sm="4" class="align-center">
                                    <VueQrcode :value="'Enrolment No: ' + formData.User.Username + '\nOrganization: ' + formData.User.OrganizationName + '\nBIN: ' + (certificateBINNo? certificateBINNo: '') + '\nService Office: ' + $globalHelpers.getServiceOfficeName(formData.ServiceOfficeId) + '\nValid Till: ' + (formData.ValidTill > 0? '30.06.'+ formData.ValidTill: $globalHelpers.getFormattedDate(formData.ValidTillDate, '', 'DD.MM.YYYY')) + '\nApproved By: Director, Export Promotion Bureau\n'+this.formActions.rootUrl+'#/certificate/'+formData.Id" :options="{ width: 180 }"></VueQrcode>
                                </v-col>
                                <v-col cols="12" sm="5" class="pt-5">
                                    <div style="font-size: 14px;">Approved By:</div>
                                    <!--                                    <div>{{ formData.EAStatusLogs[formData.EAStatusLogs.length - 1].CreatedByUser.Name }}</div>-->
                                    <div>
                                        Director, {{ formData.User.ExporterType === $globalEnums.ExporterTypes.Textile? 'Textile': 'Policy & Planning' }}
                                    </div>
                                    <div style="font-size: 14px;">Export Promotion Bureau, {{ $globalHelpers.getServiceOfficeName(formData.ServiceOfficeId) }}</div>
                                </v-col>
                            </v-row>
                            <i class="font-size-12 mt-3">N.B. This is a system generated certificate and does not require a manual signature.</i>
                        </div>
                    </div>
                </div>


            </section>
        </vue-html2pdf>
    </div>

</template>

<script>
import Vue from 'vue';
import { required, email, min, min_value, numeric, length } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';

import 'viewerjs/dist/viewer.css';
import { directive as viewer } from "v-viewer"

import VueHtml2pdf from 'vue-html2pdf'

import VueQrcode from '@chenfengyuan/vue-qrcode';

//setInteractionMode('eager');
extend('required', {
    ...required,
    message: '{_field_} can not be empty',
});
extend('email', {
    ...email,
    message: 'This field must be an email'
});
extend('min', {
    ...min,
    message: 'This field must be {length} characters'
});
extend('min_value', {
    ...min_value,
    message: 'This field must be greater than {min}'
});
extend('numeric', {
    ...numeric,
    message: 'This field must be a number'
});
extend('length', {
    ...length,
    message: 'This field must be exactly {length} characters'
});

export default {
    name: "EA_view",
    data: () => ({
        loading: false,

        selectedSectionId: 0,

        formActions: {
            formEditEnabled: false,
            paymentOptionEnabled: false,
            permissionMap: [],
            allowedStatuses: [],
            allowedPaymentOption: false,
            allowPaymentYearsEdit: false,
            paymentErrorMsg: null,
            payslipNumber: null,
            payslipExporterNo: null,
            payslipTemporaryExporter: false,
            nextStatusId: null,
            viewStatusChangeModal: false,
            statusChangeUsername: null,
            statusChangeComment: null,
            statusChangeBinNo: null,
            statusChangeTinNo: null,
            statusChangeFactoryTypes: [],
            searchFactoryTypeKeyword: null,
            filteredFactoryTypes: [],
            generateAutomaticInspection: false,
            viewInspectionModal: false,
            inspectionModalTitle: 'Inspection Team',
            availableInspectors: [],
            selectedInspectors: [],
            searchInspectionOfficerKeyword: null,
            filteredInspectors: [],
            selectedTeamLeader: null,
            hscodeSearchKeyword: null,
            hsCodeSearchResults: [],
            selectedHSCodes: [],
            status_change_msg_acdn_open: false,
            viewAdminEAUpdateModal: false,
            noteModal:{
                show: false,
                note: null
            },
            viewExporterUpdateModal: false,

            rootUrl: '',
        },

        attachments: [],

        attachment:{
            attachmentInputFileName: null,
        },

        currentAttachmentData:{
            attachmentDataId: null,
            attachmentDataNumber: null,
            attachmentDataDate: null,
        },

        formData: {
            Id: null,
            OfficeAddress: null,
            OfficeDistrictId: null,
            OfficeThanaId: null,
            OfficePhone: null,
            OfficeEmail: null,
            OfficeWebsite: null,
            FactoryAddress: null,
            FactoryDistrictId: null,
            FactoryThanaId: null,
            FactoryPhone: null,
            FactoryEmail: null,
            FactoryWebsite: null,
            ProductsDescription: null,
            PaymentYears: null,
            PaymentStatus: null,
            PaymentMethod: null,
            PaymentInstrument: null,
            PaymentRequestNo: null,
            PaymentRequestDate: null,
            PaymentTransactionId: null,
            TransactionStatus: null,
            PaymentAmount: null,
            PaymentCharged: null,
            PaymentMessage: null,
            PaymentDate: null,
            ApplicationType: null,
            StatusId: null,
            Created: null,
            CreatedBy: null,
            Modified: null,
            ModifiedBy: null,
            IsDeleted: null,
            DeletionTime: null,
            DeletedBy: null,
            UserId: null,
            ServiceOfficeId: 1,
            TINNo: null,
            BINNo: null,
            RexNo: null,
            ExporterNote: null,
            ValidFromDate: null,
            ValidTillDate: null,
            LateRenewal: 0,
            EAAttachmentDatas: [],
            EAAttachments: [],
            EAStatusLogs: [],
            EAInspectors: [],
            EAInspections: [],
            EAInspectionId: null,
            User:{
                OrganizationName: null,
                ExporterType: null,
                Username: null,
                BINNo: null,
                TINNo: null,
            },
            ValidFrom: 0,
            ValidTill: 0,
            HSCodeMappings: [],
        },
        renewal:{
            type: 0,
            renewalYears: 1,
            lateYears: 0,
            advanceYears: 0,
        },
        renewalYearsList:[0, 1, 2, 3, 4, 5],
        years:[2018,2019,2020,2021,2022,2023,2024,2025],

        factoryTypes: [],

        printModal: false,
    }),
    components: {
        ValidationProvider,
        ValidationObserver,
        VueHtml2pdf,
        VueQrcode
    },
    directives: {
        viewer: viewer({
            debug: false,
        }),
    },
    computed:{
        numberOfYears: function () {
            let years = [];
            for(let i = 0; i < 5; i++){
                if( i + this.renewal.lateYears <= 5){
                    years.push(i);
                }
            }
            return years;
        },
        currentAttachmentItem: function(){
            if(this.selectedSectionId < 100) {
                return this.attachments.find(o => o.id === this.selectedSectionId);
            }
            return {};
        },
        isRequiredAttachmentsUploaded: function () {
            for(let i = 0; i < this.attachments.length; i++){
                if(this.attachments[i].required){
                    if(this.formData.EAAttachments.filter(e => e.Type === this.attachments[i].id).length < 1){
                        return false;
                    }
                }
            }
            return true;
        },
        uploadedImageAttachments: function () {
            let items = [];
            for(let i = 0; i < this.formData.EAAttachments.length; i++){
                if(this.formData.EAAttachments[i].MimeType !== 'application/pdf') {
                    items.push(this.formData.EAAttachments[i]);
                }
            }
            return items;
        },
        uploadedPdfAttachments: function () {
            let items = [];
            for(let i = 0; i < this.formData.EAAttachments.length; i++){
                if(this.formData.EAAttachments[i].MimeType === 'application/pdf') {
                    items.push(this.formData.EAAttachments[i]);
                }
            }
            return items;
        },
        attachmentsWithDataRequired: function () {
            let output = this.attachments.filter(o => o.numberRequired || o.dateRequired);
            for(let i = 0; i < output.length; i++){
                if(this.formData.EAAttachmentDatas) {
                    let formAttachmentData = this.formData.EAAttachmentDatas.find(o => o.Type === output[i].id);
                    output[i].Number = formAttachmentData ? formAttachmentData.Number : null;
                    output[i].Date = formAttachmentData ? formAttachmentData.Date : null;
                }else{
                    output[i].Number = null;
                    output[i].Date = null;
                }
            }
            return output;
        },
        certificateRexNo: function (){
            if(this.formData.EAInspections.length > 0){
                if(this.formData.EAInspections[0].RexNo){
                    return this.formData.EAInspections[0].RexNo;
                }
            }
            if(this.formData.RexNo){
                return this.formData.RexNo;
            }
            return false;
        },
        certificateBINNo: function (){
            if(this.formData.EAInspections.length > 0){
                if(this.formData.EAInspections[0].BINNo){
                    return this.formData.EAInspections[0].BINNo;
                }
                if(this.formData.BINNo){
                    return this.formData.BINNo;
                }
                if(this.formData.User.BINNo){
                    return this.formData.User.BINNo;
                }
            }
            return false;
        },
        certificateTINNo: function (){
            if(this.formData.EAInspections.length > 0){
                if(this.formData.EAInspections[0].TINNo){
                    return this.formData.EAInspections[0].TINNo;
                }
                if(this.formData.TINNo){
                    return this.formData.TINNo;
                }
                if(this.formData.User.TINNo){
                    return this.formData.User.TINNo;
                }
            }
            return false;
        },
        certificateFactoryTypes: function (){
            return this.formData.EAInspections.length > 0? (this.formData.EAInspections[0].EAInspectionFactoryTypes.length > 0? this.formData.EAInspections[0].EAInspectionFactoryTypes: false): false;
        },
        certificateApprovalUserRoleId: function (){
            return this.formData.EAStatusLogs.length > 0? this.formData.EAStatusLogs[this.formData.EAStatusLogs.length - 1].CreatedByUser.RoleId: false;
        },
        certificateApprovalUserDepartment: function (){
            return this.formData.EAStatusLogs.length > 0? this.formData.EAStatusLogs[this.formData.EAStatusLogs.length - 1].CreatedByUser.ExporterType: false;
        },
    },
    methods: {
        selectSection: function(sectionId){
            this.selectedSectionId = sectionId;
            if(sectionId < 100) {
                let attachmentDataItem = this.formData.EAAttachmentDatas.find(o => o.Type === this.selectedSectionId);

                this.currentAttachmentData.attachmentDataId = attachmentDataItem? attachmentDataItem.attachmentDataId: null;
                this.currentAttachmentData.attachmentDataNumber = attachmentDataItem? attachmentDataItem.Number: null;
                this.currentAttachmentData.attachmentDataDate = attachmentDataItem? (attachmentDataItem.Date? this.$globalHelpers.getFormattedDate(attachmentDataItem.Date, '', 'YYYY-MM-DD'): null): null;
            }else{
                this.currentAttachmentData.attachmentDataId = null;
                this.currentAttachmentData.attachmentDataNumber = null;
                this.currentAttachmentData.attachmentDataDate = null;
            }
        },
        save: async function () {
            this.loading = true;
            this.prepareFormDataHSCodes();
            await this.$axios.post(this.$globalSettings.api.endpoints.ea.save, this.formData).then(async (response) => {
                this.loading = false;
                Vue.$toast.open({ message: response.data.Msg,  type: response.data.Status});
                if(response.data.Status === 'success'){
                    await this.loadSavedData();
                }
            }).catch((error) => {
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
            });
        },
        signup: async function () {
            await this.$router.push({name: 'Signup'});
        },
        uploadImage: async function (file) {
            if(file) {
                let save = true;
                if(this.currentAttachmentItem.numberRequired && !this.currentAttachmentData.attachmentDataNumber){
                    save = false;
                }
                if(this.currentAttachmentItem.dateRequired && !this.currentAttachmentData.attachmentDataDate){
                    save = false;
                }

                if(save) {
                    this.loading = true;
                    let fileUpload = await this.$globalHelpers.saveFile(file, this.$globalSettings.api.endpoints.ea.saveAttachment, {
                        eaId: this.formData.Id,
                        type: this.selectedSectionId,
                        attachmentDataId: this.currentAttachmentData.attachmentDataId,
                        attachmentDataNumber: this.currentAttachmentData.attachmentDataNumber,
                        attachmentDataDate: this.currentAttachmentData.attachmentDataDate,
                    });
                    this.loading = false;
                    Vue.$toast.open({message: fileUpload.Msg, type: fileUpload.Status});
                    this.attachment.attachmentInputFileName = null;
                    await this.loadSavedData();
                }else{
                    this.attachment.attachmentInputFileName = null;
                    Vue.$toast.open({message: 'Please Fill the required fields!', type: 'error'});
                }
            }
        },
        loadSavedData: async function () {
            this.loading = true;
            await this.$axios.get(this.$globalSettings.api.endpoints.ea.get, { params: { id: this.$route.params.id} }).then(async (response) => {
                this.loading = false;
                if (response.data.Status === 'success') {
                    this.formData = response.data.Data;
                    this.formActions.rootUrl = response.data.RootURL;
                    this.preparePermissionMap();
                    this.prepareAttachments();
                    await this.prepareFormAction();

                    //this.prepareSelectedTeamMembers();

                    this.preparePayment();
                    this.prepareSelectedHSCodes();
                    this.prepareAdditionalData();
                    //console.log(this.formData.ValidFrom);
                    /*if(this.formData.ValidFrom === 0){
                        this.formData.ValidFrom = Number(this.$globalHelpers.getDateToday('YYYY'));
                    }*/
                    await this.executeTabChanges();
                }else{
                    Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                }
            }).catch((error) => {
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
            });
        },
        prepareFormAction: async function () {
            this.formActions.allowedStatuses = [];
            this.formActions.allowedPaymentOption = false;
            this.formActions.formEditEnabled = false;
            let permissionObj = this.formActions.permissionMap.find(x => x.statusId === this.formData.StatusId);

            //For Non-Dhaka service office Department head for Renewal
            if(this.formData.ServiceOfficeId !== this.$globalEnums.ServiceOffices.Dhaka &&
                this.formData.ServiceOfficeId !== this.$globalEnums.ServiceOffices.Chattogram &&
                (
                    this.formData.ApplicationType === this.$globalEnums.ApplicationTypes.Renewal ||
                    this.formData.ApplicationType === this.$globalEnums.ApplicationTypes.Address_Change
                ) &&
                (
                    this.formData.StatusId === this.$globalEnums.ApplicationStatuses.Director_desk ||
                    this.formData.StatusId === this.$globalEnums.ApplicationStatuses.Report_Submitted
                ) &&
                this.$globalHelpers.getAuthStoreValue('user_is_department_head') === 1 &&
                this.formData.ServiceOfficeId === this.$globalHelpers.getAuthStoreValue('user_service_office_id'))
            {
                this.formActions.allowedStatuses = permissionObj.allowedStatuses.filter(x => x.id !== this.$globalEnums.ApplicationStatuses.Director_desk && x.id !== this.$globalEnums.ApplicationStatuses.DD_Desk && x.id !== this.$globalEnums.ApplicationStatuses.AD_desk && x.id !== this.$globalEnums.ApplicationStatuses.Enrollment_Executive_Desk && x.id !== this.$globalEnums.ApplicationStatuses.DA_desk);

                if(this.$globalHelpers.getAuthStoreValue('user_role_id') === this.$globalEnums.roles.DD){
                    this.formActions.allowedStatuses.unshift({id: this.$globalEnums.ApplicationStatuses.Enrollment_Executive_Desk, label: 'Send to Enrollment Executive', icon: 'fas fa-chevron-left', iconLeft: true}, {id: this.$globalEnums.ApplicationStatuses.AD_desk, label: 'Send to Assistant Director', icon: 'fas fa-chevron-left', iconLeft: true})
                }
                if(this.$globalHelpers.getAuthStoreValue('user_role_id') === this.$globalEnums.roles.AD){
                    this.formActions.allowedStatuses.unshift({id: this.$globalEnums.ApplicationStatuses.Enrollment_Executive_Desk, label: 'Send to Enrollment Executive', icon: 'fas fa-chevron-left', iconLeft: true})
                }
            }
            //for normal form operations according to the role and status
            else if (permissionObj.nextStatusRoleIds.filter(e => e === this.$globalHelpers.getAuthStoreValue('user_role_id')).length > 0) {
                //If registration, applications can only be handled by Dhaka office users and users from other offices who has an inspection duty for that application
                if(this.formData.ApplicationType === this.$globalEnums.ApplicationTypes.Registration){
                    if(this.$globalHelpers.getAuthStoreValue('user_service_office_id') === this.$globalEnums.ServiceOffices.Dhaka ){
                        this.formActions.allowedStatuses = permissionObj.allowedStatuses;
                    }
                }
                //if Renewal, only service office users get access to status buttons
                else if((this.formData.ApplicationType === this.$globalEnums.ApplicationTypes.Renewal || this.formData.ApplicationType === this.$globalEnums.ApplicationTypes.Address_Change) && this.formData.ServiceOfficeId === this.$globalHelpers.getAuthStoreValue('user_service_office_id')){

                    if(this.formData.ServiceOfficeId === this.$globalEnums.ServiceOffices.Dhaka){
                        if(this.formData.User.ExporterType === this.$globalHelpers.getAuthStoreValue('user_exporter_type')){
                            this.formActions.allowedStatuses = permissionObj.allowedStatuses;
                        }else{
                            this.formActions.allowedStatuses = [];
                        }
                    }else{
                        this.formActions.allowedStatuses = permissionObj.allowedStatuses;
                    }
                }
            }

            if (this.formData.ApplicationType !== this.$globalEnums.ApplicationTypes.Address_Change && permissionObj.paymentOptionRoleIds.filter(e => e === this.$globalHelpers.getAuthStoreValue('user_role_id')).length > 0) {
                //this.formActions.allowedPaymentOption = true;
                if(this.formData.ApplicationType === this.$globalEnums.ApplicationTypes.Renewal){
                    if(this.formData.PaymentYears > 0){
                        this.formActions.allowedPaymentOption = true;
                    }else{
                        this.formActions.paymentErrorMsg = 'Invalid Renewal Years. Please contact help desk.';
                    }
                }else{
                    this.formActions.allowedPaymentOption = true;
                }

                this.formActions.allowPaymentYearsEdit = this.formData.LateRenewal? false: true;
            }
            if (permissionObj.editRoleIds.filter(e => e === this.$globalHelpers.getAuthStoreValue('user_role_id')).length > 0 || this.$globalHelpers.getAuthStoreValue('user_username') === 'iia-bd') {
                this.formActions.formEditEnabled = true;
            }

            this.formActions.generateAutomaticInspection = this.formActions.nextStatusId === this.$globalEnums.ApplicationStatuses.Approved && this.formData.ApplicationType === this.$globalEnums.ApplicationTypes.Renewal && this.formData.EAInspections.length === 0;

            //this.factoryTypes = this.formData.User.ExporterType === this.$globalEnums.ExporterTypes.Textile? this.$globalSettings.textileFactoryTypes: this.$globalSettings.nonTextileFactoryTypes;
            this.factoryTypes = await this.$globalHelpers.getFactoryTypes(this.formData.User.ExporterType === this.$globalEnums.ExporterTypes.Textile ? this.$globalEnums.ExporterTypes.Textile : this.$globalEnums.ExporterTypes.NonTextile, true);
            //console.log(this.factoryTypes);
        },
        insertStatusChangeNote: function (msg){
            this.formActions.statusChangeComment = (this.formActions.statusChangeComment? this.formActions.statusChangeComment : '') + msg + '\n';
        },


        prepareAttachments: function (){
            this.attachments = [];
            let attachmentsTemp = [];
            if(this.formData.User.ExporterType === this.$globalEnums.ExporterTypes.Textile){
                attachmentsTemp = this.$globalSettings.ea_textile_attachments;
            }
            if(this.formData.User.ExporterType === this.$globalEnums.ExporterTypes.NonTextile){
                attachmentsTemp = this.$globalSettings.ea_non_textile_attachments;
            }
            for(let i = 0; i < attachmentsTemp.length; i++ ){
                if(
                    ((attachmentsTemp[i].applicationType === 'Both' || attachmentsTemp[i].applicationType === 'Registration') && this.formData.ApplicationType === this.$globalEnums.ApplicationTypes.Registration) ||
                    ((attachmentsTemp[i].applicationType === 'Both' || attachmentsTemp[i].applicationType === 'Renewal') && (this.formData.ApplicationType === this.$globalEnums.ApplicationTypes.Renewal || this.formData.ApplicationType === this.$globalEnums.ApplicationTypes.Address_Change))
                ){
                    this.attachments.push(attachmentsTemp[i]);
                }
            }
        },
        isAttachmentSectionComplete: function (id) {
            return this.formData.EAAttachments.filter(e => e.Type === id).length > 0;
        },
        deleteAttachment: async function (id) {
            if (confirm("Are your sure that you want to delete this file?") === true) {
                this.loading = true;
                await this.$axios.get(this.$globalSettings.api.endpoints.ea.deleteAttachment, {params: {id: id}}).then(async (response) => {
                    this.loading = false;
                    Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                    if (response.data.Status === 'success') {
                        await this.loadSavedData();
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.$globalHelpers.processHttpErrorsToast(error);
                });
            }
        },
        /*getAttachmentDataItem: function(type){
            for(let i =0; i < this.formData.EAAttachmentDatas.length; i++){
                if()
            }
        },*/
        requestSPG: async function () {
            if(this.renewal.renewalYears !== null /* || (this.formData.ApplicationType === this.$globalEnums.ApplicationTypes.Registration || this.formData.ApplicationType === this.$globalEnums.ApplicationTypes.Address_Change)*/) {
                this.formData.PaymentYears = this.renewal.renewalYears;
                this.formData.LatePaymentYears = this.renewal.lateYears;
                this.formData.AdvancePaymentYears = this.renewal.advanceYears;
                await this.save();
                this.loading = true;
                await this.$axios.get(this.$globalSettings.api.endpoints.spg.getSessionToken, {
                    params: {
                        eaId: this.formData.Id,
                        years: this.renewal.renewalYears
                    }
                }).then(async (response) => {
                    //console.log(response.data.status);
                    if (response.data.status === '200' && response.data.message === 'success') {
                        window.location.href = this.$spgPaymentPortalUrl + response.data.session_token;
                    } else {
                        this.loading = false;
                        Vue.$toast.open({
                            message: "Failed to load online payment gateway! Please try again.",
                            type: response.data.status
                        });
                        console.log(response.data.message);
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.$globalHelpers.processHttpErrorsToast(error);
                });
            }else{
                Vue.$toast.open({message: 'Please select number of years', type: 'error'});
            }
        },
        preparePayment: function(){
            this.renewal.renewalYears = this.formData.PaymentYears;
            if(this.formData.StatusId === this.$globalEnums.ApplicationStatuses.New && this.formData.ApplicationType !== this.$globalEnums.ApplicationTypes.Registration){
                this.formActions.payslipExporterNo = this.formData.User.Username;
                this.formActions.payslipTemporaryExporter = false;
            }else{
                this.formActions.payslipTemporaryExporter = true;
            }

            if(this.formData.ApplicationType !== this.$globalEnums.ApplicationTypes.Registration){
                if(this.formData.LateRenewal){
                    this.renewal.type = 1;
                    if(this.formData.AdvancePaymentYears === 0) {
                        this.renewal.lateYears = this.formData.PaymentYears;
                    }else{
                        this.renewal.lateYears = this.formData.LatePaymentYears;
                    }
                    this.renewal.advanceYears = this.formData.AdvancePaymentYears;
                }else{
                    this.renewal.type = 2;
                    this.renewal.lateYears = 0;
                    this.formData.AdvancePaymentYears = this.formData.PaymentYears;
                    this.renewal.advanceYears = this.formData.AdvancePaymentYears;
                }
                //this.prepareRenewalYears();
            }
        },
        prepareRenewalYears: function (){
            if(this.renewal.type === 2){
                this.renewal.lateYears = 0;
            }

            this.renewal.renewalYears = this.renewal.lateYears + this.renewal.advanceYears;
        },
        checkPayslip: async function(){
            if(!this.formActions.payslipNumber){
                Vue.$toast.open({message: "Please enter a Payslip Number!", type: 'error'});
            }
            if(!this.formActions.payslipExporterNo){
                Vue.$toast.open({message: "Please enter an Exporter Number!", type: 'error'});
            }
            if(this.formActions.payslipNumber.length < 18){
                Vue.$toast.open({message: "Please enter a valid Payslip Number!", type: 'error'});
            }else{
                console.log('payslip number ok');
                this.formData.PaymentYears = this.renewal.renewalYears;
                this.formData.LatePaymentYears = this.renewal.lateYears;
                this.formData.AdvancePaymentYears = this.renewal.advanceYears;
                await this.save();
                this.loading = true;
                await this.$axios.get(this.$globalSettings.api.endpoints.payslip.verify, {params: {eaId: this.formData.Id, payslipNo: this.formActions.payslipNumber, exporterNumber: this.formActions.payslipExporterNo, years: this.renewal.renewalYears}
                }).then(async (response) => {
                    //console.log(response.data.status);
                    if (response.data.Status === 'success') {
                        await this.loadSavedData();
                    } else {
                        this.loading = false;
                        Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                        console.log(response.data.Msg);
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.$globalHelpers.processHttpErrorsToast(error);
                });
            }
        },
        prepareStatusChange: async function (nextStatusId) {
            this.formActions.generateAutomaticInspection = nextStatusId === this.$globalEnums.ApplicationStatuses.Approved && this.formData.ApplicationType === this.$globalEnums.ApplicationTypes.Renewal && this.formData.EAInspections.length === 0;

            if (nextStatusId === this.$globalEnums.ApplicationStatuses.Inspection || nextStatusId === this.$globalEnums.ApplicationStatuses.Team_Approval) {
                /*if(this.$globalHelpers.getAuthStoreValue('user_role_id') === this.$globalEnums.roles.Director && this.formData.ApplicationType === this.$globalEnums.ApplicationTypes.Registration){
                    nextStatusId = this.$globalEnums.ApplicationStatuses.Team_Approval;
                }*/
                this.formActions.searchInspectionOfficerKeyword = null;
                this.formActions.availableInspectors = [];
                this.formActions.filteredInspectors = [];
                this.formActions.selectedInspectors = [];
                await this.getInspectionUsers();
                /*if(this.formData.StatusId !== this.$globalEnums.ApplicationStatuses.Team_Approval){
                    this.formActions.selectedInspectors = [];
                    this.formActions.selectedTeamLeader = null;
                }*/
                this.formActions.viewInspectionModal = true;
                this.formActions.inspectionModalTitle = nextStatusId === this.$globalEnums.ApplicationStatuses.Inspection ? 'Send Inspection Team' : 'Inspection Team Approval';
            } else {
                this.formActions.statusChangeComment = null;
                this.formActions.viewStatusChangeModal = true;
                this.formActions.status_change_msg_acdn_open = false;
                if (this.formActions.generateAutomaticInspection) {
                    this.formActions.statusChangeBinNo = this.formData.User.BINNo;
                    this.formActions.statusChangeTinNo = this.formData.User.TINNo;
                }
            }
            this.formActions.nextStatusId = nextStatusId;
        },
        getUsernamePrefix: function(){
            return this.formData.User.ExporterType === this.$globalEnums.ExporterTypes.Textile? 'BD': 'NT';
        },
        changeStatus: async function () {
            this.loading = true;
            await this.$axios.post(this.$globalSettings.api.endpoints.ea.saveStatusLog, {
                EAId: this.formData.Id,
                UserId: this.formData.User.Id,
                Username: this.formActions.statusChangeUsername? this.getUsernamePrefix() + this.formActions.statusChangeUsername: null,
                ServiceOfficeId: this.formData.ServiceOfficeId,
                ValidFrom: this.formData.ValidFrom,
                ValidTill: this.formData.ValidTill,
                BinNo: this.formActions.statusChangeBinNo,
                TinNo: this.formActions.statusChangeTinNo,
                FactoryTypeIds: this.formActions.statusChangeFactoryTypes,
                GenerateAutomaticInspection: this.formActions.generateAutomaticInspection,
                Comment: this.formActions.statusChangeComment,
                StatusId: this.formActions.nextStatusId,
                SendLetter: this.formActions.nextStatusId === this.$globalEnums.ApplicationStatuses.Approved || this.formActions.nextStatusId === this.$globalEnums.ApplicationStatuses.Rejected,
            }).then(async (response) => {
                this.loading = false;
                Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                if (response.data.Status === 'success') {
                    this.formActions.viewStatusChangeModal = false;
                    await this.loadSavedData();
                    if(this.formActions.nextStatusId === this.$globalEnums.ApplicationStatuses.Team_Approval || this.formActions.nextStatusId === this.$globalEnums.ApplicationStatuses.Inspection){
                        await this.saveInspectors();
                    }

                }
            }).catch((error) => {
                console.log(error);
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
            });
        },
        showNoteModal: function (note){
            this.formActions.noteModal.show = true;
            this.formActions.noteModal.note = note;
        },
        getInspectionUsers: async function () {
            this.loading = true;
            await this.$axios.post(this.$globalSettings.api.endpoints.user.list, {
                RoleIds: [this.$globalEnums.roles.DA, this.$globalEnums.roles.Enrollment_Executive, this.$globalEnums.roles.AD, this.$globalEnums.roles.DD, this.$globalEnums.roles.Director, this.$globalEnums.roles.Admin],
                //ExporterTypes: [this.formData.User.ExporterType],
                Skip: 0,
                Take: 100
            }).then(async (response) => {
                this.loading = false;
                if (response.data.Status === 'success') {
                    this.formActions.availableInspectors = response.data.List;
                    this.searchInspectionOfficer();
                }else{
                    Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                }
            }).catch((error) => {
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
            });
        },
        searchInspectionOfficer: function (){
            this.formActions.filteredInspectors = [];
            //this.formActions.selectedInspectors = [];
            if(!this.$globalHelpers.isEmptyOrUndefined(this.formActions.searchInspectionOfficerKeyword)){
                for(let i = 0; i < this.formActions.availableInspectors.length; i++){
                    if(
                        this.formActions.availableInspectors[i].Name.toUpperCase().includes(this.formActions.searchInspectionOfficerKeyword.toUpperCase()) ||
                        (this.formActions.availableInspectors[i].Username && this.formActions.availableInspectors[i].Username.toUpperCase().includes(this.formActions.searchInspectionOfficerKeyword.toUpperCase())) ||
                        (this.formActions.availableInspectors[i].DesignationId && this.$globalHelpers.getUserDesignationName(this.formActions.availableInspectors[i].DesignationId).toUpperCase().includes(this.formActions.searchInspectionOfficerKeyword.toUpperCase()))
                    ){
                        this.formActions.filteredInspectors.push(this.formActions.availableInspectors[i]);
                    }
                }
            }else{
                this.formActions.filteredInspectors = this.formActions.availableInspectors;
            }
            this.prepareSelectedTeamMembers();
        },

        //execute this function when DG loads the EA to populate Team selection modal with saved Inspectors in the previous statuses
        prepareSelectedTeamMembers: function (){
            if(this.formData.StatusId === this.$globalEnums.ApplicationStatuses.Team_Approval && this.$globalHelpers.getAuthStoreValue('user_role_id') === this.$globalEnums.roles.DG){
                //this.formActions.selectedInspectors = [];
                this.formActions.selectedTeamLeader = null;

                //console.log(this.formData.EAInspections[0].EAInspectors);
                for(let i = 0; i < this.formData.EAInspections[0].EAInspectors.length; i++){
                    if (!this.formActions.selectedInspectors.some(e => e.Id === this.formData.EAInspections[0].EAInspectors[i].User.Id)) {
                        this.formActions.selectedInspectors.push(this.formData.EAInspections[0].EAInspectors[i].User);
                    }
                    if(this.formData.EAInspections[0].EAInspectors[i].isLeader){
                        this.formActions.selectedTeamLeader = this.formData.EAInspections[0].EAInspectors[i].User.Id;
                    }
                }
            }
        },
        getSearchInspectionOfficerProfilePic(officer){
            if(!this.$globalHelpers.isEmptyOrUndefined(officer.ProfilePicPath)){
                return this.$apiBaseURL + this.$globalSettings.api.endpoints.media.profilePics + officer.ProfilePicPath;
            }
            return '/images/user.png';
        },
        saveInspectors: async function(){
            let inspectionId = this.formData.EAInspections[0].Id;
            //console.log(inspectionId);
            this.loading = true;
            let selectedInspectors = [];
            for(let i = 0; i < this.formActions.selectedInspectors.length; i++){
                selectedInspectors.push(this.formActions.selectedInspectors[i].Id);
            }

            await this.$axios.post(this.$globalSettings.api.endpoints.ea.saveInspectors, {
                EAInspectionId: inspectionId,
                InspectorIds: selectedInspectors,
                LeaderId: this.formActions.selectedTeamLeader
            }).then(async (response) => {
                this.loading = false;
                if (response.data.Status === 'success') {
                    this.formActions.viewInspectionModal = false;
                    //this.changeStatus();
                }else{
                    Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                }
            }).catch((error) => {
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
            });
        },
        getStatusInspection: function (inspectionId) {
            if(this.formData.EAInspections) {
                let inspection = this.formData.EAInspections.find(x => x.Id === inspectionId);
                //console.log(inspection.EAInspectors);
                if(inspection){
                    //console.log(inspection.EAInspectors);
                    return inspection.EAInspectors;
                }
            }
            return [];
        },
        searchHSCode: async function(){
            if(this.formActions.hscodeSearchKeyword.length !== 4){
                this.formActions.hsCodeSearchResults = [];
                return false;
            }
            this.loading = true;
            this.formActions.hsCodeSearchResults = [];
            await this.$axios.get(this.$globalSettings.api.endpoints.hscode.search, { params: { keyword: this.formActions.hscodeSearchKeyword} }).then(async (response) => {
                if (response.data.Status === 'success') {
                    this.formActions.hsCodeSearchResults = response.data.Data;
                }else{
                    Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                }
                this.loading = false;
                //console.log(this.$refs.asd.$el);
                //this.$refs.asd.$el.focus();
            }).catch((error) => {
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
                //this.$refs.asd.$el.focus();
            });
        },
        prepareSelectedHSCodes: function (){
            this.formActions.selectedHSCodes = [];
            for(let i = 0; i < this.formData.HSCodeMappings.length; i++){
                this.formActions.selectedHSCodes.push(this.formData.HSCodeMappings[i].HSCode);
            }
        },
        prepareAdditionalData: function (){
            if(this.formData.StatusId === this.$globalEnums.ApplicationStatuses.New && this.formData.TINNo === null){
                this.formData.TINNo = this.formData.User.TINNo;
            }
            if(this.formData.StatusId === this.$globalEnums.ApplicationStatuses.New && this.formData.BINNo === null){
                this.formData.BINNo = this.formData.User.BINNo;
            }
        },
        executeTabChanges: async function (){
            if(this.formData.StatusId === this.$globalEnums.ApplicationStatuses.New && this.formActions.formEditEnabled){
                if(!this.isRequiredAttachmentsUploaded && this.formActions.selectedHSCodes.length > 0){
                    for(let i = 0; i < this.attachments.length; i++){
                        if(this.attachments[i].required && !this.isAttachmentSectionComplete(this.attachments[i].id)){
                            this.selectSection(this.attachments[i].id);
                            console.log('current section', this.attachments[i].label);
                            break;
                        }
                    }
                }else if(this.isRequiredAttachmentsUploaded && this.formActions.selectedHSCodes.length > 0){
                    console.log('current section', 'Payment');
                    this.selectSection(102);
                }
                else{
                    console.log('current section', 'Form');
                    this.selectSection(101);
                }
            }
        },
        formCopyOfficeToFactory: function (){
            this.formData.FactoryAddress = this.formData.OfficeAddress;
            this.formData.FactoryEmail = this.formData.OfficeEmail;
            this.formData.FactoryPhone = this.formData.OfficePhone;
            this.formData.FactoryWebsite = this.formData.OfficeWebsite;
            this.formData.FactoryDistrictId = this.formData.OfficeDistrictId;
            this.formData.FactoryThanaId = this.formData.OfficeThanaId;
        },
        addHSCode: async function(hsCode){
            if(this.formActions.selectedHSCodes.some(x => x.Id === hsCode.Id)){
                Vue.$toast.open({message: 'You have already added this HS code', type: 'warning'});
            }else{
                this.formActions.selectedHSCodes.push(hsCode);
            }
        },
        removeHSCode: async function(hsCode){
            this.formActions.selectedHSCodes = this.formActions.selectedHSCodes.filter(x => x.Id !== hsCode.Id);
        },
        prepareFormDataHSCodes: function (){
            this.formData['HSCodeIds'] = [];
            for(let i = 0; i < this.formActions.selectedHSCodes.length; i++){
                this.formData.HSCodeIds.push(this.formActions.selectedHSCodes[i].Id);
            }
        },

        searchFactoryType: function (){
            this.formActions.filteredFactoryTypes = [];
            if(!this.$globalHelpers.isEmptyOrUndefined(this.formActions.searchFactoryTypeKeyword)){
                console.log(`keyword: ${this.formActions.searchFactoryTypeKeyword}`);
                for(let i = 0; i < this.factoryTypes.length; i++){
                    if(this.factoryTypes[i].Label.toUpperCase().includes(this.formActions.searchFactoryTypeKeyword.toUpperCase())){
                        //console.log(this.formActions.searchFactoryTypeKeyword);
                        //console.log(this.factoryTypes[i].Label);
                        this.formActions.filteredFactoryTypes.push(this.factoryTypes[i]);
                    }
                }
            }else{
                this.formActions.filteredFactoryTypes = this.factoryTypes;
                console.log(`keyword: empty`);
            }
            //this.prepareSelectedTeamMembers();
        },
        clearFactoryTypeSearch: function (){
            this.formActions.searchFactoryTypeKeyword = null;
            this.searchFactoryType();
        },

        updateExporter: async function(){
            this.loading = true;
            let inspectionId = this.formData.EAInspections[0].Id;
            await this.$axios.post(this.$globalSettings.api.endpoints.ea.updateCertificateData, {
                UserId: this.formData.User.Id,
                Username: this.formActions.statusChangeUsername? (this.getUsernamePrefix() + this.formActions.statusChangeUsername): null,
                InspectionId: inspectionId,
                BinNo: this.formActions.statusChangeBinNo,
                TinNo: this.formActions.statusChangeTinNo,
                FactoryTypeIds: this.formActions.statusChangeFactoryTypes
            }).then(async (response) => {
                this.loading = false;
                if (response.data.Status === 'success') {

                    this.formActions.viewExporterUpdateModal = false;
                    /*if(this.formActions.statusChangeUsername) {
                        this.formData.User.Username = this.getUsernamePrefix() + this.formActions.statusChangeUsername;
                    }
                    if(this.formActions.statusChangeBinNo) {
                        this.formData.EAInspections[0].BINNo = this.formActions.statusChangeBinNo;
                    }
                    if(this.formActions.statusChangeTinNo) {
                        this.formData.EAInspections[0].TINNo = this.formActions.statusChangeTinNo;
                    }
                    if(this.formActions.statusChangeFactoryTypes.length > 0) {
                        this.formData.EAInspections[0].EAInspectionFactoryTypes = this.formActions.statusChangeFactoryTypes;
                    }*/
                    await this.loadSavedData();
                    await this.pdfCertificate();
                }else{
                    Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                }
            }).catch((error) => {
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
            });
        },
        updateAdminEAData: async function(){
            this.loading = true;
            this.prepareFormDataHSCodes();
            await this.$axios.post(this.$globalSettings.api.endpoints.ea.updateAdminEAData, this.formData).then(async (response) => {
                this.loading = false;
                Vue.$toast.open({ message: response.data.Msg,  type: response.data.Status});
                if(response.data.Status === 'success'){
                    this.formActions.viewAdminEAUpdateModal = false;
                    await this.loadSavedData();
                }
            }).catch((error) => {
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
            });
        },
        pdfNotes: async function () {
            console.log('PDF generate command');
            await this.$refs.html2PdfNotes.generatePdf();
        },
        pdfApplication: async function () {
            console.log('PDF generate command');
            await this.$refs.html2PdfApp.generatePdf();
        },
        pdfPayment: async function () {
            console.log('PDF generate command');
            await this.$refs.html2PdfPay.generatePdf();
        },
        pdfCertificate: async function () {
            //alert(this.formData.User.Username);
            if(!this.formData.User.Username || !this.certificateBINNo || !this.certificateTINNo || !this.certificateFactoryTypes){
                if(this.$globalHelpers.getAuthStoreValue('user_role_id') !== this.$globalEnums.roles.Exporter) {
                    this.formActions.viewExporterUpdateModal = true;
                    this.searchFactoryType();
                }else{
                    Vue.$toast.open({message: 'Certificate is not ready yet! Please contact EPB.', type: 'error'});
                }
            }else {
                console.log('PDF Certificate generate command');
                await this.$refs.html2PdfCert.generatePdf();
            }
        },
        pdfAppStarted: function () {
            console.log('PDF Started');
            this.loading = true;
        },
        pdfAppProgress: function (event) {
            console.log(event);
            console.log('PDF Progress');
            this.loading = event > 99? this.loading = false: this.loading = true;
        },
        pdfAppCompleted: function (event) {
            console.log(event);
            console.log('PDF Completed');
            this.loading = false;
        },
        navigateToInspection: async function (inspectionId) {
            await this.$router.push({name: 'App.EA.Inspection', params: {id: inspectionId}});
        },
        navigationBack: async function () {
            this.$router.go(-1);
        },
        preparePermissionMap: function () {
            let statuses = this.$globalEnums.ApplicationStatuses;
            let roles = this.$globalEnums.roles;
            this.formActions.permissionMap = [
                {
                    statusId: statuses.New,
                    allowedStatuses: [],
                    nextStatusRoleIds: [roles.Exporter],
                    editRoleIds: [roles.Exporter],
                    paymentOptionRoleIds: [roles.Exporter]
                },
                {
                    statusId: statuses.Enrollment_Executive_Desk,
                    allowedStatuses: [
                        {id: statuses.Rejected, label: 'Reject', icon: 'fas fa-ban', iconLeft: true},
                        {id: statuses.AD_desk, label: 'Send to Assistant Director', icon: 'fas fa-chevron-right', iconLeft: false},
                        {id: statuses.DD_Desk, label: 'Send to Deputy Director', icon: 'fas fa-chevron-right', iconLeft: false}
                    ],
                    nextStatusRoleIds: [roles.Enrollment_Executive],
                    editRoleIds: [],
                    paymentOptionRoleIds: []
                },
                {
                    statusId: statuses.AD_desk,
                    allowedStatuses: [
                        {id: statuses.Enrollment_Executive_Desk, label: 'Send to Enrollment Executive', icon: 'fas fa-chevron-left', iconLeft: true},
                        {id: statuses.Rejected, label: 'Reject', icon: 'fas fa-ban', iconLeft: true},
                        {id: statuses.DD_Desk, label: 'Send to Deputy Director', icon: 'fas fa-chevron-right'},
                        {id: statuses.Director_desk, label: 'Send to Director', icon: 'fas fa-chevron-right'}
                    ],
                    nextStatusRoleIds: [roles.AD],
                    editRoleIds: [],
                    paymentOptionRoleIds: []
                },
                {
                    statusId: statuses.DD_Desk,
                    allowedStatuses: [
                        {id: statuses.Enrollment_Executive_Desk, label: 'Send to Enrollment Executive', icon: 'fas fa-chevron-left', iconLeft: true},
                        {id: statuses.AD_desk, label: 'Send to Assistant Director', icon: 'fas fa-chevron-left', iconLeft: true},
                        {id: statuses.Director_desk, label: 'Send to Director', icon: 'fas fa-chevron-right', iconLeft: false}],
                    nextStatusRoleIds: [roles.DD],
                    editRoleIds: [],
                    paymentOptionRoleIds: []
                },
                {
                    statusId: statuses.Director_desk,
                    allowedStatuses: [
                        ...this.formData.ApplicationType === this.$globalEnums.ApplicationTypes.Registration ?
                            (this.$globalHelpers.getAuthStoreValue('user_service_office_id') === this.$globalEnums.ServiceOffices.Dhaka?
                                [
                                    {id: statuses.AD_desk, label: 'Send to Asst. Dir', icon: 'fas fa-chevron-left', iconLeft: true},
                                    {id: statuses.DD_Desk, label: 'Send to Dep. Dir', icon: 'fas fa-chevron-left', iconLeft: true},
                                    //rejected status button added here based on the recomm. of EPB/Saidul 06/26/2023
                                    {id: statuses.Rejected, label: 'Reject', icon: 'fas fa-ban', iconLeft: true},
                                    ...(this.formData.EAStatusLogs.some(x => x.StatusId === this.$globalEnums.ApplicationStatuses.Report_Submitted)?
                                            [
                                                {id: statuses.Approved, label: 'Approve', icon: 'fas fa-check', iconLeft: true},
                                                //rejected status button removed from this condition to the above portion based on the recomm. of EPB/Saidul 06/26/2023
                                                //{id: statuses.Rejected, label: 'Reject', icon: 'fas fa-ban', iconLeft: true},
                                                {id: statuses.DG_desk, label: 'Send to DG Desk', icon: 'fas fa-chevron-right', iconLeft: false},
                                            ] : [
                                                {id: statuses.Team_Approval, label: 'Team Approval', icon: 'fas fa-user-friends', iconLeft: true},
                                                ...(
                                                    this.formData.User.ExporterType === this.$globalEnums.ExporterTypes.NonTextile?
                                                        [
                                                            {id: statuses.Inspection, label: 'Send Inspection', icon: 'fas fa-industry', iconLeft: true}
                                                        ]:
                                                        []
                                                )
                                            ]
                                    )
                                ]:
                                []
                            ) :
                            (
                                this.formData.ServiceOfficeId === this.$globalHelpers.getAuthStoreValue('user_service_office_id')?
                                    [
                                        {id: statuses.AD_desk, label: 'Send to Asst. Dir', icon: 'fas fa-chevron-left', iconLeft: true},
                                        {id: statuses.DD_Desk, label: 'Send to Dep. Dir', icon: 'fas fa-chevron-left', iconLeft: true},
                                        {id: statuses.Inspection, label: 'Send for Inspection', icon: 'fas fa-industry', iconLeft: true},
                                        {id: statuses.DG_desk, label: 'Send to DG Desk', icon: 'fas fa-chevron-right', iconLeft: false},
                                        {id: statuses.Approved, label: 'Approve', icon: 'fas fa-check', iconLeft: true},
                                        {id: statuses.Rejected, label: 'Reject', icon: 'fas fa-ban', iconLeft: true}
                                    ] :
                                    []
                            )
                    ], nextStatusRoleIds: [roles.Director], editRoleIds: [], paymentOptionRoleIds: []
                },
                {
                    statusId: statuses.Team_Approval,
                    allowedStatuses: [
                        {id: statuses.Director_desk, label: 'Send to Director', icon: 'fas fa-chevron-left', iconLeft: true},
                        {id: statuses.Inspection, label: 'Approve Team', icon: 'fas fa-industry', iconLeft: true},],
                    nextStatusRoleIds: [roles.DG],
                    editRoleIds: [],
                    paymentOptionRoleIds: []
                },
                {
                    statusId: statuses.DG_desk,
                    allowedStatuses: [
                        {id: statuses.Director_desk, label: 'Send to Director', icon: 'fas fa-chevron-left', iconLeft: true},
                       /* {
                            id: statuses.Approved, label: 'Approve', icon: '', iconLeft: false
                        },
                        {
                            id: statuses.Rejected,
                            label: 'Reject',
                            icon: '',
                            iconLeft: false
                        }*/
                    ],
                    nextStatusRoleIds: [roles.DG],
                    editRoleIds: [],
                    paymentOptionRoleIds: []
                },
                {
                    statusId: statuses.Inspection,
                    allowedStatuses: [],
                    nextStatusRoleIds: [],
                    editRoleIds: [],
                    paymentOptionRoleIds: []
                },
                //{ statusId: statuses.Inspection_Submitted, allowedStatuses: [], nextStatusRoleIds: [], editRoleIds: [], paymentOptionRoleIds: [] },
                {
                    statusId: statuses.Report_Submitted,
                    allowedStatuses: [
                        {id: statuses.AD_desk, label: 'Send to Asst. Dir', icon: 'fas fa-chevron-left', iconLeft: true},
                        {id: statuses.DD_Desk, label: 'Send to Dep. Dir', icon: 'fas fa-chevron-left', iconLeft: true},
                        {id: statuses.Approved, label: 'Approve', icon: 'fas fa-check', iconLeft: true},
                        {id: statuses.Rejected, label: 'Reject', icon: 'fas fa-ban', iconLeft: true},
                        {id: statuses.DG_desk, label: 'Send to DG Desk', icon: 'fas fa-chevron-right', iconLeft: false},
                    ], nextStatusRoleIds: [roles.Director], editRoleIds: [], paymentOptionRoleIds: []
                },
                {
                    statusId: statuses.Approved,
                    allowedStatuses: [],
                    nextStatusRoleIds: [],
                    editRoleIds: [],
                    paymentOptionRoleIds: []
                },
                {
                    statusId: statuses.Rejected,
                    allowedStatuses: [{
                        id: statuses.Enrollment_Executive_Desk,
                        label: 'Send to Enrollment Executive',
                        icon: '',
                        iconLeft: false
                    }],
                    nextStatusRoleIds: [roles.Exporter],
                    editRoleIds: [roles.Exporter],
                    paymentOptionRoleIds: []
                },
            ];
            //console.log(this.formActions.permissionMap);
        }
    },
    async created() {
        if (this.$route.params.paymentStatus) {
            this.selectSection(102);
        } else {
            this.selectSection(100);
        }

        await this.loadSavedData();
    }
}
</script>

<style scoped>
.app-name{
    color: #794986;
    font-size: 20px;
}
.background{
    background: rgb(101,74,134);
    background: linear-gradient(228deg, rgba(101,74,134,1) 0%, rgba(84,66,145,1) 100%);
}
.login-left-bg{

}
.login-right-bg{
    background: #512DA8;
}
.file-input{
    max-width: 375px;
}
</style>